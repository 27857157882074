import axios from "axios";

export async function GetRequester(token) {
  let rest = await axios.get(
    `${process.env.REACT_APP_COMPRADOR}/v1/comprador/unidades-compradora-usuario`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",

        Authorization: `Bearer ${token}`,
      },
    }
  );

  return rest.data;
}
