export const API_DOCUMENTOS = 'https://documentos.api.pcpdev.com.br';

export const requestConfig = (method, data, token = null) => {

    let config

    if (method === 'DELETE') {
        config = {
            method,
            headers: {},
        }
    } else {
        config = {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
};
