import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SideNavigateContext } from "../../contexts/context";
import "./MenuRetractable.scss";
import { ListEtapas } from "../../Dictionary/ListEtapas";

const MenuRetractable = (props) => {
  const navigationRef = useRef(null);
  function CloseMenu() {
    props.setOpenSideNavigate(true);
  }
  useEffect(() => {
    const targetElement = document.getElementById(props.ativo);

    if (targetElement) {
      const offsetTop = targetElement.offsetTop * 2;
      const navHeight = navigationRef.current.offsetHeight;
      navigationRef.current.scrollTo({
        top: offsetTop - navHeight,
        behavior: "smooth",
      });
    }
  }, [props.ativo]);
  return (
    <aside className="mt-4 aside_retractable">
      <div className="menu_retractable">
        <div className="barra_superior_retractable">
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
        <div ref={navigationRef} className="scroll_menu_retractable">
          <nav id="nav_menu" className="">
            <ul>
              {ListEtapas.map((etapa) => {
                return (
                  <li
                    id={etapa.posit}
                    className={props.ativo == etapa.posit ? "ativo" : ""}
                  >
                    {props.code ? (
                      <Link
                        className="link"
                        to={`${etapa.route}?code=${props.code}`}
                      >
                        {etapa.ordem}
                      </Link>
                    ) : (
                      <Link className="link" to={`${etapa.route}`}>
                        {etapa.ordem}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuRetractable;
