import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import Logo from "./../../assets/logo/logo192.svg";

import * as jose from "jose";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./navbar.scss";
function Navbar({ linkHome = true, ...props }) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [TokenDecode, setTokenDecode] = useState("");
  const [hours, setHours] = useState(
    new Date().getHours().toString().padStart(2, "0")
  );
  const [minutes, setMinutes] = useState(
    new Date().getMinutes().toString().padStart(2, "0")
  );
  const [seconds, setSeconds] = useState(
    new Date().getSeconds().toString().padStart(2, "0")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setHours(new Date().getHours().toString().padStart(2, "0"));
      setMinutes(new Date().getMinutes().toString().padStart(2, "0"));
      setSeconds(new Date().getSeconds().toString().padStart(2, "0"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);

  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }

  const exit = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  return (
    <div className="header ">
      <div className=" mt-4  container ">
        <div className="headerContainer ">
          <div className="">
            <img src={Logo} alt="Logo" width="140" />
          </div>
          <div className=" text d-flex flex-column">
            <span>
              Bem-vindo(a)
              <a> {TokenDecode.unique_name}</a>
            </span>
          </div>
          <div className="gap-2">
            {/*<img src={require("../../assets/icons/chamada.png")} />
            <img src={require("../../assets/icons/whatsapp.png")} />
            <img src={require("../../assets/icons/Notificação.png")} />
            <img src={require("../../assets/icons/person.png")} />*/}
            <img src={require("../../assets/icons/sair.png")} type="button" onClick={() => {
              dispatch({
                type: "LOGOUT",
              });
            }} alt="sair" />
          </div>
          <div className=" d-flex ">
            <div className="relogio">
              <p className="horario  ">
                {hours}:{minutes}:{seconds}
              </p>
              <p className="localidade">Horário de Brasília</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className=" mx-3 col-sm-10">
          <hr />
          {linkHome && (
            <Link className="link" to={props.link} href="#">
              {props.NomeLink}
            </Link>
          )}
        </div>
      </div>
      <div className="titulo-principal">
        <div className="container">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="">
              <Link
                className="link"
                to={`${process.env.REACT_APP_SUBDOMAIN_PCA}?token=${state.token}`}
                href="#"
              >
                {props.subtitulo}
              </Link>
              <h1>{props.titulo}</h1>
            </div>
            <div className="" data-testid="button-nav-topo">
              {props.button ? (
                <Button
                  className="btn btn-warning w-100"
                  onClick={props.onClick}
                >
                  {props.button.text}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
