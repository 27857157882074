import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import CadastroEtapasDescricao from "../pages/TermoReferencia/CadastroEtapasDescricao/CadastroEtapasDescricao";
import NumeroProcesso from "../pages/TermoReferencia/CadastroNumeroPrecesso/NumeroProcesso";

import Anexos from "../pages/TermoReferencia/CadastroAnexos/Anexos";
import Responsaveis from "../pages/TermoReferencia/CadastroResponsaveis/Responsaveis";

import AuthContext from "../contexts/auth";
import { Auth } from "../pages/Auth";
import AcrescentarPerguntas from "../pages/TermoReferencia/AcrescentarPerguntas/AcrescentarPerguntas";
import DescricaoObjeto from "../pages/TermoReferencia/DescricaoObjeto/DescricaoObjeto";
import { DetalhamentoItens } from "../pages/TermoReferencia/DetalhamentoItens/DetalhamentoItens";
import { GerarArquivo } from "../pages/TermoReferencia/GerarArquivo/GerarArquivo";
import { ListagemInicial } from "../pages/TermoReferencia/ListagemInicial/ListagemInicial";

const AppRoutes = () => {
  const ListRoutes = [
    {
      name: "Informações Básicas",
      route: "/informacoes-basicas",
      ordem: "1",
      component: <NumeroProcesso />,
    },
    {
      name: "Acrescentar Perguntas",
      route: "/acrescentar-perguntas",
      ordem: "2",
      component: <AcrescentarPerguntas />,
    },
    {
      name: "Descrição do Objeto",
      route: "/descricao-objeto",
      ordem: "3",
      component: <DescricaoObjeto />,
    },
    {
      name: "Detalhamento dos Itens",
      route: "/detalhamento-itens",
      ordem: "3.1",
      component: <DetalhamentoItens />,
    },
    {
      name: "Prazo do Contrato e Possível prorrogação",
      route: "/prazo-contrato-e-possivel-prorrogacao",
      ordem: "3.2",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Fundamentação da Contratação",
      route: "/fundamentacao-contratacao",
      ordem: "4",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Descrição da solução",
      route: "/descricao-solucao",
      ordem: "5",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Requisitos da Contratação",
      route: "/requisito-contatacao",
      ordem: "6",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Modelo de Execução do Objeto",
      route: "/execucao-objeto",
      ordem: "7",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Modelo de Gestão do Contrato",
      route: "/gestao-contrato",
      ordem: "8",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Critérios de Medição e Pagamento",
      route: "/criterio-medicao-pagemnto",
      ordem: "9",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Critérios de Seleção do Fornecedor",
      route: "/criterio-selecao-fornecedor",
      ordem: "10",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Estimativa do Valor da Contratação",
      route: "/estimativa-valor-contatacao",
      ordem: "11",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Adequação Orçamentária",
      route: "/adequacao-orcamentaria",
      ordem: "12",
      component: <CadastroEtapasDescricao />,
    },
    {
      name: "Responsáveis",
      route: "/responsaveis",
      ordem: "13",
      component: <Responsaveis />,
    },
    { name: "Anexos", route: "/anexos", ordem: "14", component: <Anexos /> },
    {
      name: "Finalizar",
      route: "/finalizar",
      ordem: "14",
      component: <GerarArquivo />,
    },
  ];

  const { state } = useContext(AuthContext);
  const Private = ({ children }) => {
    if (!state.isAuthenticated) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />

        {ListRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.route}
            element={<Private>{route.component}</Private>}
          />
        ))}
        <Route
          exact
          path="/listagem-termo"
          element={
            <Private>
              <ListagemInicial />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
