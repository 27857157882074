import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import "./GerarArquivo.scss";

import Footer from "../../../components/Footer/Footer";
import { ModalError } from "../../../components/ModalError/ModalError";
import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";

import AuthContext from "../../../contexts/auth";

import axios from "axios";
import { searchTermo } from "../../../functions/ETP/searchTermo";
import { PreviewEtapa } from "../../../components/PreviewEtapa/PreviewEtapa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ModalGerarArquivo } from "../../../components/ModalGerarArquivo/ModalGerarArquivo";


export const GerarArquivo = () => {




  const [code, setCode] = useState("");
  const { state } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [loadBasic, setLoadBasic] = useState(false);
  const [DataTermo, setDataTermo] = useState({});
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '');

  const [showModalGerarArquivo, setShowModalModalGerarArquivo] = useState(false);

  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataDataTermo(code);
    }
  }, []);


  const handleOpenModalGerarArquivo = () => {
    setShowModalModalGerarArquivo(true);
  };

  const handleCloseModalGerarArquivo = () => {
    setShowModalModalGerarArquivo(false);
  };

  async function getDataDataTermo(code) {
    // console.log("code", code);
    try {
      await searchTermo(state.token, code).then((result) => {
        setDataTermo(result);
        //console.log("result", result);
        setLoadBasic(true);
      });
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleSubmit() {
    console.log("DataTermo", DataTermo);
    try {
      const data = {
        Codigo: DataTermo.Codigo,
        DescricaoObjeto: DataTermo.DescricaoObjeto,
        CodigoTermoReferencia: DataTermo.CodigoTermoReferencia,
        NumeroProcessoInterno: DataTermo.NumeroProcessoInterno,
        SepararItensLote: DataTermo.SepararItensPorLote, // Certifique-se de que este campo está correto e não é alterado
        FormaDisputaLote: DataTermo.FormaDisputaLote,
        CodigoUnidadeRequisitante: DataTermo.CodigoUnidadeRequisitante,
        Status: 2,
      };

      // console.log("data antes do envio", data); // Adicione um log para verificar o payload antes do envio

      const response = await axios.put(
        `${URL_API}/api/v1.0/termo-referencia`,
        data,
        {
          headers: {
            unidade_compradora: DataTermo.CodigoUnidadeRequisitante,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      // console.log("response", response); // Adicione um log para verificar a resposta do servidor

      let timerInterval;
      MySwal.fire({
        title: "Finalizado com sucesso!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then(() => {
        navigate("/");
      });

    } catch (error) {
      console.error("Erro ao finalizar:", error); // Adicione um log para capturar erros
      ModalError(error);
    }
  }

  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={17} code={code} />

            {loadBasic ? (
              <div className="col-sm-9 mt-4">
                <h2>Finalizar</h2>

                <div>

                  <PreviewEtapa />
                </div>

                <div className="d-flex justify-content-end mt-5">
                  <div className=" d-flex flex-row">
                    <Button
                      className="m-2 btn btn-warning"
                      onClick={() => navigate("/")}
                    >
                      Voltar
                    </Button>
                    <Button className="m-2" onClick={handleOpenModalGerarArquivo}>
                      Gerar PDF
                    </Button>

                    <Button
                      className="m-2 btn_outline"
                      onClick={() => handleSubmit()}
                    >
                      Finalizar
                    </Button>
                  </div>
                </div>
                <ModalGerarArquivo
                  show={showModalGerarArquivo}
                  setShow={setShowModalModalGerarArquivo}
                />

              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};
