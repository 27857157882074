import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup } from "react-bootstrap";
import { MdCancel } from "react-icons/md";

export function FormResponsible(index, RemoveItem) {
  return (
    <div key={index} className="d-flex justify-content-between w-100">
      <FormGroup className="mb-3  w-100 p-1 formGroup">
        <label className="">
          <span className="d-flex flex-row align-items-center">
            Responsável
          </span>
        </label>
        <Field
          type="text"
          className="form-control"
          id={`responsavel-${index}`}
          name={`responsavel-${index}`}
        />
        <ErrorMessage name={`responsavel-${index}`} component="p" />
        <Field
          hidden
          type="text"
          className=" position-absolute "
          id={`code-${index}`}
          name={`code-${index}`}
        />
      </FormGroup>

      <FormGroup className="mb-3 p-1 w-75 formGroup">
        <label className="">
          <span className="d-flex flex-row align-items-center">CPF</span>
        </label>
        <Field type="text" className="form-control" name={`cpf-${index}`} />
        <ErrorMessage name={`cpf-${index}`} component="p" />
      </FormGroup>
      <FormGroup className="mb-3 p-1 w-50 formGroup">
        <label className="">
          <span className="d-flex flex-row align-items-center">Cargo</span>
        </label>
        <Field type="text" className="form-control" name={`cargo-${index}`} />
        <ErrorMessage name={`cargo-${index}`} component="p" />
      </FormGroup>

      <div className=" d-flex align-items-center mt-3 ">
        <MdCancel type="button" style={{ color: "red" }} onClick={RemoveItem} />
      </div>
    </div>
  );
}
