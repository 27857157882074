import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import documentosService from "../services/documentosService";

const initialState = {
    documentos: [],
    base64: null,
    metadados: [],
    modelo: {},
    tipoDocumento: [],
    error: false,
    loading: false,
    success: false,
    message: null,
    base64: null
};

// Obter Documento
export const documento = createAsyncThunk(
    "/documento/fetch",
    async (params, thunkAPI) => {
        try {
            const data = await documentosService.getDocumento(params);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Obter Metadados
export const getMetadados = createAsyncThunk(
    "/documento/metadados",
    async (params, thunkAPI) => {
        try {
            const data = await documentosService.getMetadados(params);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Obter Modelo
export const getModelo = createAsyncThunk(
    "/documento/modelo",
    async (params, thunkAPI) => {
        try {
            const data = await documentosService.getModelo(params);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Obter Tipo Documento
export const getTipoDocumento = createAsyncThunk(
    "/documento/tipodocumento",
    async (params, thunkAPI) => {
        try {
            const data = await documentosService.getTipoDocumento(params);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Gerar Documento
export const gerarDocumento = createAsyncThunk(
    "/documento/gerar",
    async ({ idDocumento, metadadoValor, isPreview }, thunkAPI) => {
        try {
            const data = await documentosService.gerarDocumento({
                idDocumento,
                metadadoValor
            });
            if (data.error) {
                return thunkAPI.rejectWithValue(data);
            }
            return { ...data, isPreview };
        } catch (error) {
            console.log("Error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const documentosSlice = createSlice({
    name: 'documentos',
    initialState,
    reducers: {
        resetMessage: (state) => {
            state.message = null;
        },
        resetBase64: (state) => {
            state.base64 = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(documento.pending, (state) => {
                state.loading = true;
            })
            .addCase(documento.fulfilled, (state, action) => {
                state.documentos = action.payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(documento.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.message = action.error.message;
            })
            .addCase(gerarDocumento.pending, (state) => {
                state.loading = true;
            })
            .addCase(gerarDocumento.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.message = action.payload.message;
                state.base64 = action.payload.base64;
                state.isPreview = action.payload.isPreview;
            })
            .addCase(gerarDocumento.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.message = action.error.message;
            })
            .addCase(getMetadados.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMetadados.fulfilled, (state, action) => {
                state.metadados = action.payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getMetadados.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.message = action.error.message;
            })
            .addCase(getModelo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getModelo.fulfilled, (state, action) => {
                state.modelo = action.payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getModelo.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.message = action.error.message;
            })
            .addCase(getTipoDocumento.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTipoDocumento.fulfilled, (state, action) => {
                state.tipoDocumento = action.payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getTipoDocumento.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.message = action.error.message;
            })
    }
});

export const { resetMessage, resetBase64 } = documentosSlice.actions;


export default documentosSlice.reducer;
