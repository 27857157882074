import styles from "./ListagemInicial.scss";

import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";

import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { DataUserContext } from "../../../contexts/context";
import Select from "react-select";

import axios from "axios";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import AuthContext from "../../../contexts/auth";

import dayjs from "dayjs";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
//import { registerLocale, setDefaultLocale } from "react-datepicker";
//import { ptBR } from "date-fns/locale";
//import { ptBR } from "date-fns/locale";
import { ModalDetail } from "../../../components/ModalDetail/ModalDetail";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage/ModalErrorMessage";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
import MaskedInput from 'react-maskedinput';
//import DatePickerInput from "../../../components/DatepikerInput/DatepickerInput";
//registerLocale('pt-BR', ptBR)
export function ListagemInicial() {

  const [date, setDate] = useState(null);
  const { userData } = useContext(DataUserContext);
  const { state } = useContext(AuthContext);

  const [RequestTermo, setRequestTermo] = useState([]);
  const navigate = useNavigate();
  const [unidadeCompradora, setUnidadeCompradora] = useState([{}]);
  const [unidadeCompradoraOptions, setUnidadeCompradoraOptions] = useState([{}]);

  const MySwal = withReactContent(Swal);
  const [dateRange, setDateRange] = useState([null, null]);

  const [showModalDetail, setShowModalDetail] = useState(false);
  const handleCloseModalDetail = () => setShowModalDetail(false);
  const handleOpenModalDetail = () => setShowModalDetail(true);
  const [currentItem, setCurrentItem] = useState(undefined);
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')


  const [Status, setStatus] = useState("");
  const [UnidadeRequisitante, setUnidadeRequisitante] = useState("");

  // Data
  const [DataCriacao, setDataCriacao] = useState(null);
  const [previousSelectedDate, setPreviousSelectedDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const handleMonthChange = (date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  };

  const handleDatePickerClose = () => {
    // Armazena a data selecionada antes de fechar o DatePicker
    setPreviousSelectedDate(DataCriacao);
    // Define a data atual quando o DatePicker é fechado
    if (DataCriacao) {
      setSelectedMonth(DataCriacao.getMonth());
      setSelectedYear(DataCriacao.getFullYear());
    } else {
      setSelectedMonth(new Date().getMonth());
      setSelectedYear(new Date().getFullYear());
    }
  };

  const handleDatePickerOpen = () => {
    // Se houver uma data preenchida, usar essa data para abrir o calendário
    if (DataCriacao) {
      setSelectedMonth(DataCriacao.getMonth());
      setSelectedYear(DataCriacao.getFullYear());
    } else if (previousSelectedDate) {
      // Restaura a data selecionada quando o DatePicker é reaberto
      setDataCriacao(previousSelectedDate);
      setSelectedMonth(previousSelectedDate.getMonth());
      setSelectedYear(previousSelectedDate.getFullYear());
    } else {
      setSelectedMonth(new Date().getMonth());
      setSelectedYear(new Date().getFullYear());
    }
  };

  const handleDateChange = (date) => {
    if (date === null) {
      // Quando o botão de limpar é clicado, resetar os estados
      setDataCriacao(null);
      setPreviousSelectedDate(null);
      setSelectedMonth(new Date().getMonth());
      setSelectedYear(new Date().getFullYear());
    } else {
      // Atualiza os estados com a nova data selecionada
      setDataCriacao(date);
      setSelectedMonth(date.getMonth());
      setSelectedYear(date.getFullYear());
    }
  };

  const handleCalendarClose = () => {
    if (!DataCriacao) {
      setSelectedMonth(new Date().getMonth());
      setSelectedYear(new Date().getFullYear());
    }
  };


  async function handleClick() {
    navigate(`/informacoes-basicas`);
  }
  async function getData(header = "") {
    try {
      await axios
        .get(`${URL_API}/api/v1.0/termo-referencia`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          if (response?.data) {
            setRequestTermo(response.data);
            getUnidadeCompradora();
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  function getUnidadeCompradora(requestID) {
    try {
      axios
        .get(
          `${process.env.REACT_APP_COMPRADOR}/v1/comprador/unidades-compradora-usuario`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",

              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setUnidadeCompradora(res?.data);

            // let options = 
            setUnidadeCompradoraOptions([{ value: "", label: "Todos" }, ...res?.data.map(item => ({ label: item.nome, value: item.codigo }))])
          }
        });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }
  async function handleRequestTermo(event) {

    const DataCriacaoFormatada = moment(
      DataCriacao,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)"
    ).format('DD/MM/YYYY');

    try {
      await axios
        .get(
          `${URL_API}/api/v1.0/termo-referencia?dataCriacao=${DataCriacao ? DataCriacaoFormatada : ""}&codigoTermo=${event.codigoTermo}&numeroProcessoInterno=${event.numeroProcessoInterno || ""}&objeto=${event.Objeto || ""}&status=${Status}&unidadeRequisitante=${UnidadeRequisitante}&nomeResponsavel=${event.Responsavel || ""}
          `,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setRequestTermo(res?.data);
          } else {
            setRequestTermo([]);
          }
        });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }
  async function handleEditEtp(codigo) {
    navigate(`/informacoes-basicas?code=${codigo}`);
  }
  async function handleViewtEtp(codigo) {
    navigate(`/gerar-arquivo?code=${codigo}`);
  }
  return (
    <>
      <Navbar
        // subtitulo="Termo de Referencia"
        titulo="Termo de Referência"
        button={{ text: "+ Cadastrar novo" }}
        onClick={handleClick}
      />

      <main className="listagem-inicial">
        <Formik
          initialValues={{
            codigoTermo: "",
            Objeto: "",
            numeroProcessoInterno: "",
            status: "",
            UnidadeRequisitante: "",
          }}
          onSubmit={handleRequestTermo}
        >
          <Form>
            <div className="container">
              <div className="d-flex flex-row justify-content-between gap-2">
                <div className="flex-fill">
                  <Field
                    type="text"
                    name="codigoTermo"
                    placeholder="Número do Termo"
                    className="form-control"
                  />
                </div>
                <div className="flex-fill">
                  <Field
                    type="text"
                    name="numeroProcessoInterno"
                    placeholder="Número do Processo Interno"
                    className="form-control"
                  />
                </div>
                {/* <div className="flex-fill">
                  <Field
                    type="text"
                    name="Responsavel"
                    placeholder="Responsável"
                    className="form-control"
                  />
                </div> */}

                <div className="flex-fill">
                  <Field
                    type="text"
                    name="Objeto"
                    placeholder="Objeto"
                    className="form-control"
                  />
                </div>
                <div className="flex-fill">
                  <Select
                    className="inputSelect"
                    name="status"
                    options={[
                      { value: "", label: "Todos" },
                      { value: "1", label: "Rascunho" },
                      { value: "2", label: "Finalizado" }
                    ]}
                    placeholder="Status"
                    onChange={(value) => {
                      setStatus(value?.value)
                    }}
                  />
                </div>


                <div className="flex-fill">

                  <DatePicker
                    name="data"
                    className="DataPicker form-control"
                    dateFormat="dd/MM/yyyy"
                    value={DataCriacao}
                    locale="pt-BR"
                    selected={DataCriacao}
                    onChange={handleDateChange}
                    onMonthChange={(date) => setSelectedMonth(date.getMonth())}
                    isClearable={true}
                    placeholderText={'Data'}
                    customInput={<MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" size="20" />}
                    dayClassName={(date) =>
                      date.getMonth() !== selectedMonth ? 'hide-outside-month' : ''
                    }
                    onCalendarClose={handleCalendarClose}
                  />

                </div>

                <div className="col-sm-1">
                  <button className="btn btn-primary btn-sm" type="submit">
                    Pesquisar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        <div className="container">
          <table className="table">
            <tbody>
              <tr className={styles.linha__tabbel_listagem}>
                <th>Número do TR</th>
                <th>Número do Processo Interno</th>
                <th>Objeto</th>
                <th>Responsável</th>
                <th>Data do Cadastro</th>

                <th>Unidade Requisitante</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>

              {RequestTermo?.map((termo) => (
                <tr key={termo.Codigo}>
                  <td>{termo.CodigoTermoReferencia}</td>
                  <td>{termo.NumeroProcessoInterno}</td>
                  <td>{removerTags(termo.DescricaoObjeto || "")}</td>
                  <td>{termo?.NomesResponsaveis?.join(', ')}</td>
                  <td>{dayjs(termo.Criado).format("DD/MM/YYYY")}</td>
                  <td>
                    {
                      unidadeCompradora?.find((item) => {
                        return (
                          item.codigo ===
                          parseInt(termo.CodigoUnidadeRequisitante)
                        );
                      })?.nome
                    }
                  </td>
                  <td>{termo.Status || 'Finalizado'}</td>
                  <td>
                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => handleEditEtp(termo.Codigo)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          handleOpenModalDetail();
                          setCurrentItem(termo);
                        }}
                      >
                        Visualizar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ModalDetail
          showModal={showModalDetail}
          handleCloseModal={handleCloseModalDetail}
          itemTr={currentItem}
        />
      </main>
      <Footer />
    </>
  );
}
