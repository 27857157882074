import axios from "axios";

export async function searchTermo(token, code = "") {
  const URL_API = process.env.REACT_APP_BASEURL.replace(/\/$/, '');

  try {
    const response = await axios.get(`${URL_API}/api/v1.0/termo-referencia`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (code !== "") {
      if (response.data) {
        const filteredTerm = response.data.find((item) => item.Codigo === code);
        return filteredTerm || null;
      }
    } else {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching termo de referência:", error);
    throw new Error("Failed to fetch termo de referência.");
  }
}

export default {
    searchTermo,
};
