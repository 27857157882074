export const ListEtapas = [
  {
    name: "Informações Básicas",
    route: "/informacoes-basicas",
    ordem: "1",
    posit: 1,
    description: "",
    justification:"",
  },
  {
    name: "Perguntas",
    route: "/acrescentar-perguntas",
    ordem: "2",
    posit: 2,
    description: "",
    justification:"",
  },
  {
    name: "Descrição do Objeto",
    route: "/descricao-objeto",
    ordem: "3",
    posit: 3,
    description: "",
    justification:"",
  },
  {
    name: "Detalhamento dos Itens",
    route: "/detalhamento-itens",
    ordem: "3.1",
    posit: 4,
    description: "",
    justification:"",
  },
  {
    name: "Prazo do Contrato e Possível prorrogação",
    route: "/prazo-contrato-e-possivel-prorrogacao",
    ordem: "3.2",
    posit: 5,
    description: "Descreva o prazo do contrato e a possibilidade de prorrogação",
    justification:"Justifique o não preenchimento do prazo do contrato e a possibilidade de prorrogação",
  },
  {
    name: "Fundamentação da Contratação",
    route: "/fundamentacao-contratacao",
    ordem: "4",
    posit: 6,
    description: "Descreva a fundamentação da contratação",
    justification:"Justifique o não preenchimento da fundamentação da contratação",
  },
  {
    name: "Descrição da solução",
    route: "/descricao-solucao",
    ordem: "5",
    posit: 7,
    description: "Descreva a solução como um todo",
    justification:"Justifique o não preenchimento da solução como um todo",
  },
  {
    name: "Requisitos da Contratação",
    route: "/requisito-contatacao",
    ordem: "6",
    posit: 8,
    description: "Descreva os requisitos da Contratação",
    justification:"Justifique o não preenchimento dos requisitos da Contratação",
  },
  {
    name: "Modelo de Execução do Objeto",
    route: "/execucao-objeto",
    ordem: "7",
    posit: 9,
    description: "Descreva o modelo de execução do Objeto",
    justification:"Justifique o não preenchimento do modelo de execução do Objeto",
  },
  {
    name: "Modelo de Gestão do Contrato",
    route: "/gestao-contrato",
    ordem: "8",
    posit: 10,
    description: "Descreva como será o modelo de gestão do contrato",
    justification:"Justifique o não preenchimento de como será o modelo de gestão do contrato",
  },
  {
    name: "Critérios de Medição e Pagamento",
    route: "/criterio-medicao-pagemnto",
    ordem: "9",
    posit: 11,
    description: "Descreva os critérios de medição e critérios de pagamento",
    justification:"Justifique o não preenchimento dos critérios de medição e critérios de pagamento",
  },
  {
    name: "Critérios de Seleção do Fornecedor",
    route: "/criterio-selecao-fornecedor",
    ordem: "10",
    posit: 12,
    description: "Descreva os critérios de seleção do Fornecedor",
    justification:"Justifique o não preenchimento dos critérios de seleção do Fornecedor",
  },
  {
    name: "Estimativa do Valor da Contratação",
    route: "/estimativa-valor-contatacao",
    ordem: "11",
    posit: 13,
    description: "Descreva a estimativa do valor da contratação",
    justification:"Justifique o não preenchimento da estimativa do valor da contratação",
  },
  {
    name: "Adequação Orçamentária",
    route: "/adequacao-orcamentaria",
    ordem: "12",
    posit: 14,
    description: "Descreva a adequação orçamentária da Contratação",
    justification:"Justifique o não preenchimento da adequação orçamentária da Contratação",
  },
  {
    name: "Responsáveis",
    route: "/responsaveis",
    ordem: "13",
    posit: 15,
    description: "",
    justification:"",
  },
  { name: "Anexos", route: "/anexos", ordem: "14", posit: 16 },
  {
    name: "Finalizar",
    route: "/finalizar",
    ordem: "15",
    posit: 17,
    description: "",
    justification:"",
  },
];
