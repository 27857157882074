import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SideNavigateContext } from "../../contexts/context";
import "./MenuFull.scss";
import { ListEtapas } from "../../Dictionary/ListEtapas";
import { GetContentEtapa } from "../../functions/Etapa/GetContentEtapa";
import AuthContext from "../../contexts/auth";
import { FaCheck } from "react-icons/fa";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";

const MenuFull = (props) => {
  const navigationRef = useRef(null);
  const { state } = useContext(AuthContext);
  const [etapas, setEtapas] = useState(false);
  const [lista, setLista] = useState([{}]);

  function CloseMenu() {
    props.setOpenSideNavigate(false);
  }

  function getOrdem(item) {
    if (item.posit == 1 && props.code) {
      return true
    }

    if (item.posit > 4 && props.code) {
      const resultado = Object.keys(EtapasApiCode).filter(chave => EtapasApiCode[chave] === (item.posit - 4));

      if (resultado) {
        return true
      }

    }

    // return etapa.ordem

    return false

  }

  useEffect(() => {
    setLista(ListEtapas)
    const targetElement = document.getElementById(props.ativo);

    if (targetElement) {
      const offsetTop = targetElement.offsetTop * 2;
      const navHeight = navigationRef.current.offsetHeight;

      navigationRef.current.scrollTo({
        top: offsetTop - navHeight,
        behavior: "smooth",
      });
    }
  }, [props.ativo]);

  useEffect(() => {
    if (props?.code) {
      GetContentEtapa(props.code, "all", state.token).then(result => {
        setEtapas(result);
        const listaComChecked = ListEtapas.map(item => {
          // Verifica se a etapa está presente nas etapas retornadas
          const etapaExistente = result?.find(etapa => etapa.NumeroEtapa === item.numero);
          // Define a propriedade "checked" com base na presença da etapa
          return { ...item, checked: etapaExistente !== undefined };
        });
        setLista(listaComChecked);
      });
    }
  }, [props.code]);


  useEffect(() => { }, [etapas]);
  return (
    <aside className="mt-4 aside_menu_full">
      <div className="container_menu">
        <div className="barra_superior">
          <h2>Etapas</h2>
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-left"></i>
          </button>
        </div>
        <div ref={navigationRef} className="scroll_menu">
          <nav id="nav_menu">
            <ul>
              {ListEtapas.map((etapa) => {

                return (
                  <li
                    id={etapa.posit}
                    className={`${props.ativo >= etapa.posit ? "ativo" : ""}`}
                    // className={(props.ativo == etapa.posit) || etapa.checked ? "ativo" : ""}
                    key={etapa.posit}
                  >

                    {/* <span>{props.ativo >= etapa.posit ? <FaCheck style={{ color: "#fff" }}/> : }</span> */}
                    <span>{etapa.checked ? <FaCheck style={{ color: "#fff" }} /> : etapa.ordem}</span>

                    {props.code ? (
                      <Link data-testeid={`link-${etapa.name}`} to={`${etapa.route}?code=${props.code}`}>
                        {etapa.name}
                      </Link>
                    ) : props.navBlock ? (
                      <p className="">{etapa.name}</p>
                    ) : (
                      <Link to={`${etapa.route}`}>{etapa.name}</Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuFull;
