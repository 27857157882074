import { Button, Modal } from "react-bootstrap";

export function ModalJustify({ showEtapa, handleCloseEtapa, handleJustify }) {
  return (
    <Modal show={showEtapa} onHide={handleCloseEtapa} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          É necessário que justifique o porquê não é necessário preencher essa
          etapa.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleJustify}>
          Entendi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
