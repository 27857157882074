import { configureStore } from '@reduxjs/toolkit';

import documentosReducer from './slices/documentosSlice';
import anexoReducer from './slices/anexoSlice';
import termoDeReferenciaReducer from './slices/termoDeReferenciaSlice';
import lotesReducer from './slices/lotesSlice';

export const store = configureStore({
    reducer: {
        documentos: documentosReducer,
        anexos: anexoReducer,
        termoDeReferencia: termoDeReferenciaReducer,
        lotes: lotesReducer
    },
});

export default store;