const CustomMessages = (status) => {
	switch (status) {
		case 400:
	    return 'A solicitação não pôde ser entendida pelo servidor devido à sintaxe incorreta. Por favor, verifique os parâmetros da sua solicitação e tente novamente.';
	    break;
		case 413:
	    return 'A solicitação é muito grande para ser processada pelo servidor. Por favor, reduza o tamanho da solicitação e tente novamente.';
	    break;
	  case 422:
	    return 'Os dados enviados são muito longos para serem processados. Por favor, verifique a precisão das informações fornecidas e tente novamente. Para assistência adicional, entre em contato com o suporte técnico.'
	    break;
      case 409:
	    return 'Não foi possível concluir a solicitação, provavelmente esta informação já existe na base de dados.'
	    break;
      case 414:
	    return "A URL da solicitação é muito longa para ser processada pelo servidor. Por favor, reduza o tamanho da URL e tente novamente."

	    break;
	  default:
	    return 'A página atual não conseguiu carregar os dados'
	}
}
export default CustomMessages
