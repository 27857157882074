import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomMessages from "../../Dictionary/CustomMessages";

export function ModalErrorMessage(message) {
  const MySwal = withReactContent(Swal);
  if (message?.response?.data?.ErrorMessages?.[0] == "Usuário não autorizado") {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: "Usuário não autorizado a acessar este serviço. Favor contatar o administrador.",
      // footer: `Erro: ${message.response?.status} `,
      backdrop: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
    return <></>;
  }
  MySwal.fire({
    icon: "error",
    title: "Oops...",
    text: CustomMessages(message.response?.status ?? 413),
    footer: `Erro: ${message.response?.status ?? "413 (Payload Too Large)"}`,
    showCancelButton: false,
    showConfirmButton: true,
  });
}
