import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalError(message) {
  const MySwal = withReactContent(Swal);
  if (message?.response?.data?.ErrorMessages?.[0] == 'Usuário não autorizado') {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: "Usuário não autorizado a acessar este serviço. Favor contatar o administrador.",
      // footer: `Erro: ${message.response?.status} `,
      backdrop: true,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    return <></>;
  }
  MySwal.fire({
    icon: "error",
    title: "Oops...",
    text: "Ocorreu um problema ao tentar carregar as informações.",
    footer: `Erro: ${message.response?.data?.ErrorMessages?.[0] ?? message.response?.status}.`,
    backdrop: false,
    showCancelButton: false,
    showConfirmButton: true,
  });
  return <></>;
}
