import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";

import { Form, Formik } from "formik";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import { ModalError } from "../../../components/ModalError/ModalError";
import AuthContext from "../../../contexts/auth";
import axios from "axios";
import { searchTermo } from "../../../functions/ETP/searchTermo";
import { FormResponsible } from "../../../functions/Responsible/FormResponsible";
import api from "../../../services/api";

const Responsavel = () => {
  const [load, setLoad] = useState(false);
  const { state } = useContext(AuthContext);

  const [request, setRequest] = useState({});
  const [code, setCode] = useState("");
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const searchParams = new URLSearchParams(location.search);
  const [valueRequester, setValueRequester] = useState([]);
  const [unidadeCompradora, setUnidadeCompradora] = useState("");
  const [init, setInit] = useState({});
  const [fields, setFields] = useState([]);
  const navigate = useNavigate();
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  async function getResponsavel(code) {
    try {
      await searchTermo(state.token, code).then((result) => {
        if (result) {
          setUnidadeCompradora(result.CodigoUnidadeRequisitante);
          axios.get(`${URL_API}/api/v1.0/responsaveis?codigoTermoReferencia=${code}`, {
            headers: {
              Authorization: `Bearer ${state.token}`,
              unidade_compradora: result.CodigoUnidadeRequisitante,
            },
          })
            .then((res) => {
              if (res.data) {
                // Aplicar filtro para excluir os itens com Status "Deletado"
                const filteredData = res.data.filter(
                  (item) => item.Status !== "Deletado"
                );
                if (filteredData.length === 0) {
                  setFields([FormResponsible(0, () => RemoveItem(0))]);
                }
                setValueRequester(filteredData);

                const initialValues = filteredData.reverse().reduce(
                  (acc, curr, index) => {
                    return {
                      ...acc,
                      [`code-${index}`]: curr.Codigo,
                      [`responsavel-${index}`]: curr.Nome,
                      [`cargo-${index}`]: curr.Cargo,
                      [`cpf-${index}`]: curr.CPF,
                    };
                  },
                  {}
                );

                setInit(initialValues);
              }
              // else {
              //   setFields([FormResponsible(0, () => RemoveItem(0))]);
              // }

              setLoad(true);
            });
        }
      });
    } catch (error) {
      ModalError(error);
    }
  }

  useEffect(() => {
    if (valueRequester?.length !== fields?.length) {
      valueRequester.map((item, index) => {
        setFields((prevFields) => [
          ...prevFields,
          FormResponsible(index, () => RemoveItem(index)),
        ]);
      });
    }
  }, [valueRequester]);
  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getResponsavel(code);
    } else {
      setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function submitData(e) {
    let error = false
    Promise.all(fields.map(async (i, index) => {
      if (e[`code-${index}`] === undefined) {

        try {
          const response = await axios.post(`${URL_API}/api/v1.0/responsaveis`,
            {
              CodigoTermoReferencia: code,
              Nome: e[`responsavel-${index}`],
              Cargo: e[`cargo-${index}`],

              CPF: e[`cpf-${index}`],
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
                unidade_compradora: unidadeCompradora,
              },
            }
          );

        } catch (e) {
          ModalError(e);
          error = true
        }
      } else {
        try {
          const response = await axios.put(`${URL_API}/api/v1.0/responsaveis`,
            {
              Codigo: e[`code-${index}`],
              Nome: e[`responsavel-${index}`],
              Cargo: e[`cargo-${index}`],

              CPF: e[`cpf-${index}`],
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
                unidade_compradora: unidadeCompradora,
              },
            }
          );
        } catch (e) {
          ModalError(e);
          error = true
        }
      }
    })).then(() => {
      console.log(error)
      if (!error) {
        navigate(`/anexos?code=${code}`);
      }
    })
  }
  const addField = () => {
    setFields([
      ...fields,
      FormResponsible(fields.length, () => RemoveItem(fields.length)),
    ]);
  };

  async function removeItemApi(code) {
    try {
      await api.delete(`/api/v1.0/responsaveis/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          unidade_compradora: unidadeCompradora,
        },
      });
    } catch (error) {
      ModalError(error);
    }
  }

  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFields((prevFields) => {
          const updatedFields = prevFields.filter((item, index) => {
            if (item.key === `${key}`) {
              removeItemApi(document.getElementById(`code-${item.key}`).value);
            }

            return item.key !== `${key}`;
          });
          // console.log(prevFields);
          return updatedFields;
        });
        MySwal.fire("Excluído!", "O responsável foi deletado.", "success");
      }
    });
  }

  return (
    <>
      <Navbar
        titulo="Responsável"
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
      />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={15} code={code} />

            <div className="col mt-4">
              <h2>Responsável</h2>

              <div className="">
                {load ? (
                  <>
                    <Formik
                      initialValues={init}
                      validationSchema={() => {
                        const schemaObject = {};

                        for (let index = 0; index < fields.length; index++) {
                          schemaObject[`responsavel-${index}`] =
                            Yup.string().required("Campo obrigatório");
                          schemaObject[`cpf-${index}`] =
                            Yup.string().required("Campo obrigatório");
                          schemaObject[`cargo-${index}`] =
                            Yup.string().required("Campo obrigatório");
                        }

                        return Yup.object().shape(schemaObject);
                      }}
                      validateOnBlur={true}
                      validateOnChange={true}
                      onSubmit={submitData}
                    >
                      {({ setFieldValue }) => (
                        <Form className="mt-3 d-flex flex-xl-column">
                          <div>{fields.map((field, index) => field)}</div>
                          <div className=" d-flex justify-content-between">
                            <Button
                              className="btn btn-primary button"
                              onClick={addField}
                            >
                              Adicionar
                            </Button>
                            <Button
                              className="btn btn-primary button"
                              type="submit"
                            >
                              Salvar e Continuar
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <></>
                  // <ResponsavelSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default Responsavel;


