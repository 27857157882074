import axios from 'axios';

export async function fetchLotes(token, code) {
    const URL_API = process.env.REACT_APP_BASEURL.replace(/\/$/, '');
    try {
        const response = await axios.get(
            `${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${code}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching lotes:', error);
        throw new Error('Failed to fetch lotes.');
    }
}
