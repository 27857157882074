/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-unused-vars

import React, { useContext, useEffect, useState } from "react";

import Navbar from "../../../components/Navbar/Navbar";

import { useLocation, useNavigate } from "react-router-dom";
// import CKeditorEtp from "../../../components/CKeditor/CKeditorEtp";
import Footer from "../../../components/Footer/Footer";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import { searchTermo } from "../../../functions/ETP/searchTermo";

import axios from "axios";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { GroupButtons } from "../../../components/GroupButons/GroupButtons";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalPreViewEtapa } from "../../../components/ModalPreViewEtapa/ModalPreViewEtapa";
import { SkeletonEtapaEditor } from "../../../components/Skeletons/SkeletonEtapaEditor";
import AuthContext from "../../../contexts/auth";
import { ModalDetail } from "../../../components/ModalDetail/ModalDetail";
import ReactQuill from "react-quill";

const DescricaoObjeto = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => {
    if (!etapaIsJustify) {
      setShowEtapa(true);
    } else {
      setEtapaIsJustify(false);
    }
  };
  const [dataEditor, setDataEditor] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [code, setCode] = useState("");
  const [DataTermo, setDataTermo] = useState({});
  const [etapaIsJustify, setEtapaIsJustify] = useState(false);
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  const [showModalDetail, setShowModalDetail] = useState(false);
  const handleCloseModalDetail = () => setShowModalDetail(false);
  const handleOpenModalDetail = () => setShowModalDetail(true);
  const [currentItem, setCurrentItem] = useState(undefined);

  const [newInput, setNewInput] = useState(false);
  async function getData(code) {
    try {
      await searchTermo(state.token, code).then((result) => {
        if (result) {
          setDataTermo(result);
          setDataEditor(result.DescricaoObjeto);
        }
        if (!!!result?.DescricaoObjeto) {
          setNewInput(true);
        }
      });
    } catch (error) {
      ModalError(error);
    }
  }
  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getData(code);
    }
  }, []);

  //console.log(DataTermo);
  async function handleSubmit() {
    try {
      let data = {
        Codigo: DataTermo.Codigo,
        DescricaoObjeto: dataEditor,
        CodigoTermoReferencia: DataTermo.CodigoTermoReferencia,
        NumeroProcessoInterno: DataTermo.NumeroProcessoInterno,
        SepararItensLote: DataTermo.SepararItensPorLote,
        FormaDisputaLote: DataTermo.FormaDisputaLote,
        CodigoUnidadeRequisitante: DataTermo.CodigoUnidadeRequisitante,
        // Status: DataTermo.Status,
      };

      await axios
        .put(
          `${URL_API}/api/v1.0/termo-referencia`,
          data,
          {
            headers: {
              unidade_compradora: DataTermo.CodigoUnidadeRequisitante,
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          navigate(`/detalhamento-itens?code=${code}`);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={3} code={code} />
            {!(DataTermo || newInput) ? (
              <SkeletonEtapaEditor />
            ) : (
              <div className="col-sm-9 mt-4">
                <div className="d-flex flex-row gap-2">
                  <h2>
                    Descrição do objeto
                    <small className={'d-block h6 mt-3 mb-0'}>Descreva o objeto da contratação</small>
                  </h2>

                </div>
                {(dataEditor || newInput) && (
                  <ReactQuill
                    name="text"
                    value={dataEditor}
                    onChange={(value) => {
                      setDataEditor(value)
                    }}
                  />
                )}
                <div className="row">
                  <div>
                    <Button
                      className="btn btn-primary m-2"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Salvar e Continuar
                    </Button>
                    <Button className="m-2 btn_outline" onClick={() => {
                      handleOpenModalDetail();
                      setCurrentItem(DataTermo);
                    }}>
                      Visualizar
                    </Button>
                  </div>

                  {/* Modal Preencher etapa */}

                  {/* Modal Visualização */}
                  {/* <ModalDetail
                    showModal={showModalDetail}
                    handleCloseModal={handleCloseModalDetail}
                    itemTr={currentItem}
                  /> */}
                  <ModalPreViewEtapa
                    showPreview={showModalDetail}
                    setShow={setShowModalDetail}

                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default DescricaoObjeto;
