import styles from "./Anexos.module.css";

import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../../components/Footer/Footer";
import { ModalError } from "../../../components/ModalError/ModalError";
import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import AuthContext from "../../../contexts/auth";
import api from "../../../services/api";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage/ModalErrorMessage";

const Anexos = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => setShowEtapa(true);
  const { state } = useContext(AuthContext);
  const inputRef = React.createRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [code, setCode] = useState("");
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
    }
  }, []);

  const handleFileChange = (event) => {
    setLoading(true)
    const files = event.target.files;
    const file = files[0];
    let data = {
      name: files?.name,
      size: files?.size,
    };

    handleUploadFile(files[0], file);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  function RemoveItem(index) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        const items = [...selectedFiles];

        removeFileApi(items[index].codigoFile);
        items.splice(index, 1);

        //limpa input para receber o mesmo arquivo
        setSelectedFiles(items);
        if (inputRef.current) {
          inputRef.current.value = null;
        }

        MySwal.fire("Excluído!", "Seu arquivo foi deletado.", "success");
      }
    });
  }
  function removeFileApi(code) {
    try {
      api
        .delete(`/api/v1.0/anexos/${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => { });
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleUploadFile(file, dataFile) {
    const formData = new FormData();
    formData.append("arquivo", file);
    formData.append("codigoTermoReferencia", code);

    try {
      const response = await fetch(
        `${URL_API}/api/v1.0/anexos`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const res = await response.json();

      if (res) {

        let data = {
          name: res?.Nome,
          size: res?.Tamanho,
        };
        let file = {
          name: res?.Nome + "." + res.Extensao,
          size: res?.Tamanho,
          codigoFile: res.Codigo,
          CodigoTermoReferencia: res.CodigoTermoReferencia,
          CodigoExterno: res.CodigoExterno,
          Status: res.Status,
        };

        setSelectedFiles([...selectedFiles, data]);
        setLoading(false)
        // let temList = selectedFiles;
        // temList[selectedFiles.length] = file;

        // setSelectedFiles(temList);
      }
    } catch (error) {
      setLoading(false)
      ModalErrorMessage(error);
    }
  }

  async function getDataAnexo(code) {
    try {
      api
        .get(`/api/v1.0/anexos?codigoTermoReferencia=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let dataRes = res.data
              .filter((item) => item.Status !== "Deletado")
              .map((item) => ({
                name: item?.Nome + "." + item.Extensao,
                size: item?.Tamanho,
                codigoFile: item.Codigo,
                CodigoTermoReferencia: item.CodigoTermoReferencia,
                CodigoExterno: item.CodigoExterno,
                Status: item.Status,
              }));

            setSelectedFiles(dataRes);
          }

          setLoad(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataAnexo(code);
    } else {
      setLoad(true);
    }
  }

  useEffect(() => {
    LoadData();
  }, []);

  function handleNavigate() {
    navigate(`/finalizar?code=${code}`);
  }

  return (
    <>
      {/* Topo */}

      <Navbar
        titulo="Gestão de risco"
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
      />
      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={16} code={code} />

            {load ? (
              <div className="col-sm-9 mt-4">
                <h2>Anexos</h2>
                <div className="container">
                  <table className="table">
                    <tbody>
                      <tr className={styles.linha__tabbel_listagem}>
                        <th>Número</th>
                        <th className="text-center">Nome</th>
                        <th className="text-center">Tamanho do Arquivo</th>
                        <th></th>
                      </tr>
                      {selectedFiles.map((file, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-center">{file.name}</td>
                          <td className="text-center">{file.size}</td>
                          <td>
                            <div>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => RemoveItem(index)}
                              >
                                Excluir
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between">
                  <div className=" mt-2 h-50 d-flex  ">
                    <Button className="" onClick={handleButtonClick} disabled={loading} style={{width: 220}}>
                      { loading ? 'Carregando' : 'Selecione o arquivo'}
                    </Button>

                    <input
                      className="position-absolute"
                      type="file"
                      ref={inputRef}
                      hidden
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className=""></div>
                  <div className="col-md-6">
                    <Button onClick={handleNavigate} className="m-2"  disabled={loading}  style={{width: 220}}>
                      Salvar e Continuar
                    </Button>
                  </div>
                  {/* Modal Preencher etapa */}
                  {/* Modal Visualização */}
                  <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Visualização</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h2>Descrição de requisitos da contratação</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed sit amet dapibus nunc, ut maximus libero. Cras nec
                        ex ullamcorper, rhoncus purus eget, auctor ipsum.
                        Phasellus fermentum dignissim efficitur. Fusce risus ex,
                        venenatis eu porta eu, aliquet eget urna. Etiam
                        fermentum mi id tellus egestas, non tincidunt magna
                        accumsan. Sed dolor tellus, cursus eget libero ut,
                        eleifend egestas tellus. Donec faucibus tortor nunc, id
                        luctus orci iaculis a. Duis cursus, urna in placerat
                        lobortis, diam nibh dictum nibh, nec venenatis risus
                        lectus ac enim. Vestibulum ante ipsum primis in faucibus
                        orci luctus et ultrices posuere cubilia curae; Nam
                        fermentum arcu ac nibh sodales, sed pretium tellus
                        tincidunt. Cras efficitur malesuada volutpat. Aenean nec
                        urna eros. Sed finibus eu velit eu congue.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleClose}>
                        Voltar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={showEtapa}
                    onHide={handleCloseEtapa}
                    animation={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Atenção</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        É necessário preencher o campo da etapa de{" "}
                        <b>Descrição de Necessidade</b>
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleCloseEtapa}>
                        Entendi
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default Anexos;
