import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import AuthContext from "../../contexts/auth";
import { GetContentEtapa } from "../../functions/Etapa/GetContentEtapa";
import { ModalErrorMessage } from "../ModalErrorMessage/ModalErrorMessage";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";
import { ListEtapas } from "../../Dictionary/ListEtapas";
import { FormataMoeda } from "../../functions/FormataMoeda";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import ModalPreviewDocumento from "../ModalPreviewDocumento/ModalPreviewDocumento";
import { ModalError } from "../ModalError/ModalError";
import { resetBase64 } from "../../slices/documentosSlice";

//CSS
import "./ModalGerarArquivo.scss";

// Redux
import { documento, getMetadados, gerarDocumento } from "../../slices/documentosSlice";
import { fetchAnexos } from "../../slices/anexoSlice";
import { fetchTermo } from "../../slices/termoDeReferenciaSlice";
import { getLotes } from "../../slices/lotesSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalGerarArquivo({ show, setShow }) {
    const [selectedDocumentoId, setSelectedDocumentoId] = useState("");
    const [dataEtapas, setDataEtapas] = useState([]);
    const [dataItem, setDataItem] = useState({});
    const [utilizaPerguntas, setUtilizaPerguntas] = useState({});
    const [perguntas, setPerguntas] = useState([]);
    const [respostaPerguntas, setRespostaPerguntas] = useState([]);
    const [load, setLoad] = useState(false);
    const { state } = useContext(AuthContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [code, setCode] = useState("");
    const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '');
    const [isPreview, setIsPreview] = useState(false);
    const [previaDocumento, setPreviaDocumento] = useState();
    const [showPrevia, setShowPrevia] = useState(false);
    const [downloadInitiated, setDownloadInitiated] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [base64Processed, setBase64Processed] = useState(false);
    const [downloadCompleted, setDownloadCompleted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [generationTriggered, setGenerationTriggered] = useState(false);




    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const { documentos, base64, metadados } = useSelector((state) => state.documentos);
    const { dataTermo, loading: loadingTermo, error: errorTermo } = useSelector((state) => state.termoDeReferencia);
    const { anexos, loading: loadingAnexos, error: errorAnexos } = useSelector((state) => state.anexos);
    const { dataLotes, loading: loadingLotes, error: errorLotes } = useSelector((state) => state.lotes);

    useEffect(() => {
        if (code) {
            dispatch(fetchTermo({ token: state.token, code }));
        }
    }, [code, state.token, dispatch]);

    useEffect(() => {
        if (code) {
            dispatch(fetchAnexos({ codigoTermoReferencia: code, token: state.token }));
        }
    }, [code, state.token, dispatch]);

    useEffect(() => {
        if (code) {
            //dispatch(fetchTermo({ token: state.token, code }));
            dispatch(getLotes({ token: state.token, code }));
        }
    }, [code, dispatch, state.token]);


    const getRespostaPerguntas = async (code) => {
        try {
            GetContentEtapa(code, 11, state.token).then(async (contentEtapa) => {
                await setRespostaPerguntas(contentEtapa);
            });
        } catch (error) {
            ModalError(error);
        }
    };


    async function getEtapas(code, currentEtapa) {
        try {
            GetContentEtapa(code, currentEtapa, state.token).then((contentEtapa) => {
                if (contentEtapa) {
                    const listaMatch = contentEtapa
                        .map((item) => EtapasApiCode[item.NumeroEtapa])
                        .filter((numero) => numero !== undefined)
                        .map((numero) => {
                            return {
                                numero: numero,
                                content: contentEtapa.find(
                                    (item) => EtapasApiCode[item.NumeroEtapa] === numero
                                ).Descricao,
                                justificada: contentEtapa.find(
                                    (item) => {
                                        return EtapasApiCode[item.NumeroEtapa] === numero;
                                    }
                                ).EtapaPreenchida,
                            };
                        });

                    const temp = listaMatch.map((valor) => {
                        const etapaCorrespondente = ListEtapas.find(
                            (etapa) => etapa.posit === valor.numero + 4
                        );
                        return {
                            ...valor,
                            nome: etapaCorrespondente ? etapaCorrespondente.name : undefined,
                        };
                    });

                    setDataEtapas(temp);
                }
            });
        } catch (error) {
            ModalErrorMessage(error);
        }
    }

    function getDetalhesItem(codigoTermo) {
        try {
            axios
                .get(
                    `${URL_API}/api/v1.0/material-servico?codigoTermoReferencia=${codigoTermo}`,
                    {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        setDataItem(res.data);
                    }
                    setLoad(true);
                });
        } catch (error) { }
    }

    async function getConfiguracao() {
        try {
            const response = await axios.get(`${(process.env.REACT_APP_BASEURL_CONFIGURACOES).replace(/\/$/, '')}/api/v1.0/ConfiguracoesAberta`,
                {
                    headers: {
                        Authorization: `Bearer ${state.token}`,
                    },
                });

            let config = {};
            let pergs = [];
            if (response?.data) {
                await response?.data.map(async (item) => {
                    if (item.Chave === 'Pergunta' && item.Status === 'Ativo') {
                        pergs.push(item);
                    }

                    if (item.Chave === 'configuracao_cadastra_perguntas_tr') {
                        config = item;
                    }
                });

                setUtilizaPerguntas(config);
                setPerguntas(pergs);

                setLoad(false);
            }
        } catch (error) {
            ModalError(error);
        }
    }




    useEffect(() => {
        const code = searchParams.get("code");
        if (code !== null) {
            setCode(code);
            getEtapas(code, "all"); // Obtenha todas as etapas
            getDetalhesItem(code);
            getLotes(code);
            getConfiguracao();
            getRespostaPerguntas(code);
        }
    }, []);

    const handleGenerateDocument = async (e) => {
        e.preventDefault();
    
        if (!selectedDocumentoId) {
            MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: "É necessário selecionar um template.",
                showCancelButton: false,
                showConfirmButton: true,
            });
            return;
        }
    
        setProcessing(true);
        dispatch(resetBase64()); // Reset base64 state in Redux
    
        try {
            const responsaveisToHTML = (responsaveis) => {
                if (!Array.isArray(responsaveis)) {
                    return '';
                }
                let tableHTML = `<table style="width: 100%; border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Responsável</th>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: center;">CPF</th>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: center;">Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>`;
                responsaveis.forEach(responsavel => {
                    tableHTML += `<tr>
                                    <td style="border: 1px solid #ddd; padding: 8px;">${responsavel.Nome}</td>
                                    <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${responsavel.CPF}</td>
                                    <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${responsavel.Cargo}</td>
                                </tr>`;
                });
                tableHTML += `</tbody>
                                </table>`;
                return tableHTML;
            };
    
            const anexosToHTML = (anexos) => {
                if (!Array.isArray(anexos)) {
                    return '';
                }
                let tableHTML = `<table style="width: 100%; border-collapse: collapse;">
                                    <thead>
                                        <tr>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Número</th>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: center;">Nome</th>
                                            <th style="border: 1px solid #ddd; padding: 8px; text-align: center;">Tamanho do Arquivo</th>
                                        </tr>
                                    </thead>
                                    <tbody>`;
                anexos.forEach((file, index) => {
                    tableHTML += `<tr>
                                    <td style="border: 1px solid #ddd; padding: 8px;">${index + 1}</td>
                                    <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${file.name}</td>
                                    <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${file.size}</td>
                                </tr>`;
                });
                tableHTML += `</tbody>
                                </table>`;
                return tableHTML;
            };
    
            const perguntasToHTML = (perguntas, respostas) => {
                if (!Array.isArray(perguntas)) {
                    return '';
                }
                let perguntasHTML = "";
                if (perguntas.length > 0) {
                    perguntas.forEach((item, index) => {
                        if (item.Status === 'Ativo') {
                            const resposta = respostas && JSON.parse(respostas.Descricao)[`resposta_${index}`] || "Não foi encontrada resposta para essa pergunta";
                            perguntasHTML += `<div>
                                            <h4 style="font-weight: bold;">${item.Valor}</h4>
                                          </div>
                                          <p>${resposta}</p>`;
                        }
                    });
                } else {
                    perguntasHTML += `<p>Sem perguntas disponíveis</p>`;
                }
                return perguntasHTML;
            };
    
            const detalhamentoItensToHTML = (separarItensPorLote, dataLotes, dataItem) => {
                let detalhamentoHTML = "";
    
                if (separarItensPorLote && dataLotes.length > 0) {
                    dataLotes.forEach((Lote, loteIndex) => {
                        detalhamentoHTML += `<div style="margin-top: 10px;">
                                                <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px;">
                                                    <thead>
                                                        <tr>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Lote</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Descrição</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Finalizado</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Valor Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style="border: 1px solid #ddd; padding: 8px;">${loteIndex + 1}</td>
                                                            <td style="border: 1px solid #ddd; padding: 8px;">${removerTags(Lote?.DescricaoLote)}</td>
                                                            <td style="border: 1px solid #ddd; padding: 8px;">${Lote?.Finalizado ? "Sim" : "Não"}</td>
                                                            <td style="border: 1px solid #ddd; padding: 8px;">${FormataMoeda(Lote?.ValorTotal)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px;">
                                                    <thead>
                                                        <tr>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Item</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Descrição</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Un. Medida</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Quantidade</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Valor Unitário</th>
                                                            <th style="border: 1px solid #ddd; padding: 8px;">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>`;
                        Lote.MateriaisServicos.forEach((MateriaisServicos, itemIndex) => {
                            detalhamentoHTML += `<tr>
                                                    <td style="border: 1px solid #ddd; padding: 8px;">${itemIndex + 1}</td>
                                                    <td style="border: 1px solid #ddd; padding: 8px; max-width: 350px; overflow: auto;">${removerTags(MateriaisServicos.DescricaoObjeto)}</td>
                                                    <td style="border: 1px solid #ddd; padding: 8px;">${MateriaisServicos.UnidadeMedida}</td>
                                                    <td style="border: 1px solid #ddd; padding: 8px;">${MateriaisServicos.Quantidade}</td>
                                                    <td style="border: 1px solid #ddd; padding: 8px;">${FormataMoeda(MateriaisServicos.ValorUnitario)}</td>
                                                    <td style="border: 1px solid #ddd; padding: 8px;">${FormataMoeda(MateriaisServicos.ValorUnitario * MateriaisServicos.Quantidade)}</td>
                                                </tr>`;
                        });
                        detalhamentoHTML += `</tbody>
                                            </table>
                                        </div>`;
                    });
                } else if (dataItem.length > 0) {
                    detalhamentoHTML += `<table style="width: 100%; border-collapse: collapse; margin-bottom: 10px;">
                                            <thead>
                                                <tr>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Item</th>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Descrição</th>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Un. Medida</th>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Quantidade</th>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Valor Unitário</th>
                                                    <th style="border: 1px solid #ddd; padding: 8px;">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>`;
                    dataItem.forEach((i, index) => {
                        detalhamentoHTML += `<tr>
                                                <td style="border: 1px solid #ddd; padding: 8px;">${index + 1}</td>
                                                <td style="border: 1px solid #ddd; padding: 8px; max-width: 350px; overflow: auto;">${removerTags(i.DescricaoObjeto)}</td>
                                                <td style="border: 1px solid #ddd; padding: 8px;">${i.UnidadeMedida}</td>
                                                <td style="border: 1px solid #ddd; padding: 8px;">${i.Quantidade}</td>
                                                <td style="border: 1px solid #ddd; padding: 8px;">${FormataMoeda(i.ValorUnitario)}</td>
                                                <td style="border: 1px solid #ddd; padding: 8px;">${FormataMoeda(i.ValorUnitario * i.Quantidade)}</td>
                                            </tr>`;
                    });
                    detalhamentoHTML += `</tbody>
                                        </table>`;
                } else {
                    detalhamentoHTML += '<p>Sem itens disponíveis</p>';
                }
    
                return detalhamentoHTML;
            };
    
            const data = {
                NumeroTermoReferencia: dataTermo.NumeroProcessoInterno,
                SepararItensPorLotes: dataTermo.SepararItensPorLote,
                FormaDisputaLote: dataTermo.FormaDisputaLote,
                Perguntas: perguntasToHTML(perguntas, respostaPerguntas),
                DescricaoObjeto: dataTermo.DescricaoObjeto,
                DetalhamentoItens: detalhamentoItensToHTML(dataTermo.SepararItensPorLote, dataLotes, dataItem),
                PrazoContratoPossivelProrrogacao: dataEtapas[0].content,
                FundamentacaoContratacao: dataEtapas[1].content,
                RequisitosContratacao: dataEtapas[3].content,
                ModeloExecucaoObjeto: dataEtapas[4].content,
                ModeloGestaoContrato: dataEtapas[5].content,
                CriteriosMedicaoPagamento: dataEtapas[6].content,
                CriteriosSelecaoFornecedor: dataEtapas[7].content,
                AdequacaoOrcamentaria: dataEtapas[9].content,
                Responsavel: responsaveisToHTML(dataTermo.Responsaveis),
                Anexos: anexosToHTML(anexos),
            };
    
            const documentoSelecionado = documentos.find(doc => doc.id === parseInt(selectedDocumentoId));
    
            if (!documentoSelecionado) {
                ModalError("Documento não encontrado. Por favor, selecione um template válido.");
                setProcessing(false);
                return;
            }
    
            const metadadoValor = documentoSelecionado.metadados.map(metadado => {
                const chave = metadado.nome;
                return {
                    id: metadado.id,
                    valor: data[chave] || "" // Usa o valor de data ou uma string vazia
                };
            });
    
            const metadadosData = {
                idDocumento: selectedDocumentoId,
                metadadoValor: metadadoValor
            };
    
            dispatch(gerarDocumento({ ...metadadosData, isPreview }));
        } catch (error) {
            ModalError(`Erro ao gerar documento: ${error.message}`);
            setProcessing(false); // Ensure processing state is reset on error
        }
    };
    


    const handlePreview = () => {
        setShowPrevia(true);
    };

    const handleClosePrevia = () => {
        setShowPrevia(false);
    };

    const handleClose = () => setShow(false);

    useEffect(() => {
        dispatch(documento());
        dispatch(getMetadados());
    }, [dispatch]);

    const handleSelectChange = (e) => {
        setSelectedDocumentoId(e.target.value);
    };

    const base64ToBlob = (base64, contentType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    useEffect(() => {
        if (base64 && processing) {
            const blob = base64ToBlob(base64, 'application/pdf');
            const url = URL.createObjectURL(blob);
    
            if (isPreview) {
                setPreviaDocumento(url);
                setShowPrevia(true);
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.download = `TR_${new Date().toISOString()}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
    
            setProcessing(false);
            dispatch(resetBase64()); // Reset base64 state in Redux
        }
    }, [base64, isPreview, processing]);
    

console.log("dataTermo", dataTermo);





    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Gerar Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup className="mb-3 p-1 w-100">
                        <label>
                            <h6 className="d-flex flex-row align-items-center">
                                Template
                            </h6>
                        </label>
                        <Form.Select className="w-100" value={selectedDocumentoId} onChange={handleSelectChange}>
                            <option value="" disabled>Selecione um Template</option>
                            {Array.isArray(documentos) && documentos.map((documento) => {
                                if (documento.tipoDocumentoId === 1 && documento.perfilAcesso === parseInt(dataTermo.CodigoUnidadeRequisitante)) {
                                    return (
                                        <option key={documento.id} value={documento.id}>
                                            {documento.titulo}
                                        </option>
                                    );
                                }
                            })}
                        </Form.Select>

                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} variant="danger">
                    Cancelar
                </Button>
                <Button type="submit" variant="primary" onClick={(e) => { setIsPreview(false); handleGenerateDocument(e); }}>
                    Gerar Documento
                </Button>
                <Button onClick={(e) => { setIsPreview(true); handleGenerateDocument(e); }} variant="primary">
                    Visualizar Prévia do Documento
                </Button>
            </Modal.Footer>
            <ModalPreviewDocumento
                show={showPrevia}
                handleClose={handleClosePrevia}
                src={previaDocumento}
            />
        </Modal>
    );
}
