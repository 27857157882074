import Select from "react-select";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Navbar from "./../../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import NavegacaoLateral from "./../../../components/NavegacaoLateral/NavegacaoLateral";
import { useContext, useEffect, useState } from "react";
import { GetRequester } from "../../../functions/Dominios/GetRequester";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormGroup } from "react-bootstrap";
import * as yup from "yup";
import axios from "axios";
import { ModalError } from "../../../components/ModalError/ModalError";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";
import { searchTermo } from "../../../functions/ETP/searchTermo";
import "./NumeroProcesso.scss";
import api from "../../../services/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useFormikContext } from 'formik';
import DescricaoObjeto from "../DescricaoObjeto/DescricaoObjeto";

const NumeroProcesso = () => {
  const [termoReferencia, setTermoReferencia] = useState({});
  const [valueRequester, setValueRequester] = useState([]);
  const [valueResponsible, setValueResponsible] = useState([]);
  const [NumProcessInterno, setNumProcessInterno] = useState("");
  const [NumTermoReferencia, setNumTermoReferencia] = useState("");
  const [FormaDisputaLote, setFormaDisputaLote] = useState("Por item");
  const [dataLote, setDataLote] = useState(null);
  const [dataItem, setDataItem] = useState([]);
  const [dataLoad, setDataLoaded] = useState(false);
  const [load, setLoad] = useState(false);
  const { setUserData } = useContext(DataUserContext);
  const { state } = useContext(AuthContext);
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '');
  const [descricaoItem, setDescricaoItem] = useState("");
  const [responsible, setResponsible] = useState([]);
  const [requestInput, setRequestInput] = useState([]);
  const [Lotes, setLotes] = useState();
  const [showEtapa, setShowEtapa] = useState(true);
  const [CodigoComprador, setCodigoComprador] = useState();
  const [code, setCode] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const optionsLotes = [
    { value: true, label: "sim" },
    { value: false, label: "não" },
  ];
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const schema = yup.object().shape({
    NumTermoReferencia: yup.string().required("Insira o Número do Termo de Referência").typeError("O Valor deve ser um texto"),
    selectRequester: yup.object().required("Defina uma Unidade Requisitante").typeError("Campo obrigatório"),
    ItensLote: yup.object().typeError("Defina um valor").required("Defina um valor"),
  });

  async function getDataETP(code) {
    try {
      await searchTermo(state.token, code).then((result) => {
        setTermoReferencia(result)
        setNumTermoReferencia(result.CodigoTermoReferencia);
        setNumProcessInterno(result.NumeroProcessoInterno);
        setCodigoComprador(result.CodigoUnidadeRequisitante);
        setUserData(result.CodigoUnidadeRequisitante);
        setLotes(result.SepararItensPorLote);
        setFormaDisputaLote(result.FormaDisputaLote == "nao implementado" ? "Valor global" : result.FormaDisputaLote);
        setDescricaoItem(result?.DescricaoObjeto ? result?.DescricaoObjeto : "");
        setDataLoaded(true);
        getLotes(result.Codigo)
        getItem(result.Codigo);
      });
      GetRequester(state.token).then((result) => {
        let res = {};
        res = (result || []).map((requester) => ({
          value: requester.codigo,
          label: requester.nome,
          requester,
        }));
        setRequestInput(res);
        setLoad(true);
        //console.log(res);
      });
    } catch (error) {
      ModalError(error);
    }
  }

  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataETP(code);
    } else {
      try {
        GetRequester(state.token).then((result) => {
          let req = {};
          req = (result || []).map((requester) => ({
            value: requester.codigo,
            label: requester.nome,
            requester,
          }));
          setRequestInput(req);
        });
        setLoad(true);
      } catch (error) {
        ModalError(error);
      }
    }
  }

  function getLotes(codigoTermo) {
    try {
      axios
        .get(`${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${codigoTermo}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          if (res.data) {
            setDataLote(res.data);
          }
        });
    } catch (error) { }
  }

  function getItem(codigoTermo) {
    try {
      axios
        .get(
          `${URL_API}/api/v1.0/material-servico?codigoTermoReferencia=${codigoTermo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setDataItem(res.data);
          }
          setLoad(true);
        });
    } catch (error) { }
  }


  async function removeItemApi(code) {
    try {
      await api.delete(`/api/v1.0/material-servico/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) { }
  }


  async function removeLoteApi(code) {
    try {
      await api.delete(`/api/v1.0/Lote/${code}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      });
    } catch (error) { }
  }

  async function handleLoteChange(selectedOptions, setFieldValue, values) {
    if (code) {
      if (!dataLote && dataItem.length > 0) { // Verifica se a opção selecionada é "não"
        const result = await MySwal.fire({
          title: "Tem certeza?",
          text: "Esta ação excluirá todos os itens existentes!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, exclua!",
          cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
          dataItem.forEach((item) => {
            removeItemApi(item.Codigo);
          });
          setDataItem([]);
          setLotes(selectedOptions.value);
          setFieldValue("ItensLote", selectedOptions);

          try {
            await axios.put(
              `${URL_API}/api/v1.0/termo-referencia`,
              {
                Codigo: code,
                CodigoTermoReferencia: values.NumTermoReferencia,
                NumeroProcessoInterno: values.NumProcessInterno,
                SepararItensLote: selectedOptions.value,
                FormaDisputaLote: FormaDisputaLote,
                CodigoUnidadeRequisitante: `${values.selectRequester.value || ""}`,
              },
              {
                headers: {
                  "unidade_compradora": `${values.selectRequester.value || ""}`,
                  Authorization: `Bearer ${state.token}`,
                },
              }
            );
          } catch (error) {
            ModalError(error);
          }
        } else {
          setFieldValue("ItensLote", values.ItensLote);
        }
      } else if (dataLote && dataLote.length > 0) {
        const result = await MySwal.fire({
          title: "Tem certeza?",
          text: "Esta ação excluirá todos os lotes existentes!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, exclua!",
          cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
          dataLote.forEach((lote) => {
            removeLoteApi(lote.Codigo);
          });
          dataItem.forEach((item) => {
            removeItemApi(item.Codigo);
          });
          setDataLote([]);
          setDataItem([]);
          setLotes(selectedOptions.value);
          setFieldValue("ItensLote", selectedOptions);

          try {
            await axios.put(
              `${URL_API}/api/v1.0/termo-referencia`,
              {
                Codigo: code,
                CodigoTermoReferencia: values.NumTermoReferencia,
                NumeroProcessoInterno: values.NumProcessInterno,
                SepararItensLote: selectedOptions.value,
                FormaDisputaLote: FormaDisputaLote,
                CodigoUnidadeRequisitante: `${values.selectRequester.value || ""}`,
              },
              {
                headers: {
                  "unidade_compradora": `${values.selectRequester.value || ""}`,
                  Authorization: `Bearer ${state.token}`,
                },
              }
            );
          } catch (error) {
            ModalError(error);
          }
        } else {
          setFieldValue("ItensLote", values.ItensLote);
        }
      } else {
        setLotes(selectedOptions.value);
        setFieldValue("ItensLote", selectedOptions);

        try {
          await axios.put(
            `${URL_API}/api/v1.0/termo-referencia`,
            {
              Codigo: code,
              CodigoTermoReferencia: values.NumTermoReferencia,
              NumeroProcessoInterno: values.NumProcessInterno,
              SepararItensLote: selectedOptions.value,
              FormaDisputaLote: FormaDisputaLote,
              CodigoUnidadeRequisitante: `${values.selectRequester.value || ""}`,
            },
            {
              headers: {
                "unidade_compradora": `${values.selectRequester.value || ""}`,
                Authorization: `Bearer ${state.token}`,
              },
            }
          );
        } catch (error) {
          ModalError(error);
        }
      }
    } else {
      setLotes(selectedOptions.value);
      setFieldValue("ItensLote", selectedOptions);
    }
  }


  useEffect(() => {
    LoadData();
  }, []);

  async function handleSubmit(e) {
    try {
      if (!code) {
        const response = await axios.post(
          `${URL_API}/api/v1.0/termo-referencia`,
          {
            CodigoTermoReferencia: e.NumTermoReferencia,
            NumeroProcessoInterno: e.NumProcessInterno,
            SepararItensLote: e.ItensLote.value,
            FormaDisputaLote: FormaDisputaLote,
            CodigoUnidadeRequisitante: `${e.selectRequester.value || ""}`,
          },
          {
            headers: {
              "unidade_compradora": `${e.selectRequester.value || ""}`,
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        //console.log(e)
        setCode(response.data.Codigo);
        navigate(`/acrescentar-perguntas?code=${response.data.Codigo}`);
      } else {
        try {
          let data = {
            Codigo: code,
            CodigoTermoReferencia: e.NumTermoReferencia,
            NumeroProcessoInterno: e.NumProcessInterno,
            SepararItensLote: e.ItensLote.value,
            FormaDisputaLote: FormaDisputaLote,
            DescricaoObjeto: termoReferencia.DescricaoObjeto,
            CodigoUnidadeRequisitante: `${e.selectRequester.value || ""}`,
          }

          await axios.put(
            `${URL_API}/api/v1.0/termo-referencia`, data,
            {
              headers: {
                "unidade_compradora": `${e.selectRequester.value || ""}`,
                Authorization: `Bearer ${state.token}`,
              }
            }
          )
            .then(() => {
              navigate(`/acrescentar-perguntas?code=${code}`);
            });
        }
        catch (error) {
          ModalError(error);
        }
      }
    } catch (error) {
      ModalError(error);
    }
  }

  useEffect(() => {
    if (requestInput.length > 0 && CodigoComprador) {
      const selectedRequester = requestInput.find(item => item.value === parseInt(CodigoComprador));
      setValueRequester(selectedRequester);
    }
  }, [requestInput, CodigoComprador]);

  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo="1" code={code} navBlock={true} termoReferencia={termoReferencia} dataLoad={dataLoad} />

            <div className="col-sm-9 mt-4">
              <h2>Informações básicas</h2>
              <div className="container">
                {load ? (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      NumTermoReferencia: NumTermoReferencia,
                      NumProcessInterno: NumProcessInterno,
                      selectRequester: valueRequester,
                      ItensLote: optionsLotes.find((item) => item.value === Lotes),
                      FormaDisputaLote: FormaDisputaLote,
                    }}
                    validationSchema={schema}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                  >

                    {({ setFieldValue, values, handleChange }) => (


                      <Form className="d-flex justify-content-end">
                        <div className="col-md-6 ">
                          <FormGroup className="mb-3 p-1 formGroup">
                            <label className="">
                              <span className="d-flex flex-row align-items-center">
                                Número do Termo de Referência
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip-2">
                                      Informe o número individualizado do termo
                                      de referência.
                                    </Tooltip>
                                  }
                                >
                                  {({ ref, ...triggerHandler }) => (
                                    <a
                                      variant="light"
                                      {...triggerHandler}
                                      className="d-inline-flex align-items-center p-1"
                                    >
                                      <i
                                        ref={ref}
                                        className="fa-solid fa-circle-question"
                                      ></i>
                                    </a>
                                  )}
                                </OverlayTrigger>
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="NumTermoReferencia"
                              data-testid="NumTermoReferencia"
                              onChange={(e) => {
                                handleChange(e);
                                setNumTermoReferencia(e.target.value);
                              }}
                            />
                            <ErrorMessage component="p" name="NumTermoReferencia" />
                          </FormGroup>

                          <FormGroup className="mb-3 p-1 formGroup">
                            <label>
                              <span className="d-flex flex-row align-items-center">
                                Unidade Requisitante
                              </span>
                            </label>
                            <Select
                              inputId="selectRequester"
                              name="selectRequester"
                              classNamePrefix="Selecione a área"
                              value={values.selectRequester}
                              placeholder="Selecione a área"
                              options={requestInput}
                              onChange={(selectedOptions) => {
                                setFieldValue("selectRequester", selectedOptions);
                                setValueRequester(selectedOptions);
                              }}
                            />
                            <ErrorMessage component="p" name="selectRequester" />
                          </FormGroup>
                          {Lotes && (
                            <FormGroup className="mb-3 p-1 formGroup ">
                              <label>
                                <span className="d-flex flex-row align-items-center">
                                  Forma de disputa do lote
                                </span>
                              </label>
                              <Select
                                inputId="FormaDisputaLote"
                                name="FormaDisputaLote"
                                classNamePrefix="Selecione"
                                value={values.FormaDisputaLote}
                                placeholder={values.FormaDisputaLote}
                                options={[
                                  { value: "Por item", label: "Por item" },
                                  { value: "Valor global", label: "Por valor global" },
                                ]}
                                onChange={(selectedOptions) => {
                                  setFieldValue("FormaDisputaLote", selectedOptions);
                                  setFormaDisputaLote(selectedOptions.value);
                                }}
                              />
                            </FormGroup>
                          )}
                        </div>

                        <div className="col-md-6">
                          <FormGroup className="mb-3 p-1 formGroup">
                            <label>
                              <span className="d-flex flex-row align-items-center">
                                Número do Processo Interno
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip-2">
                                      Informe o número do processo interno onde
                                      se encontra o Termo de Referência
                                    </Tooltip>
                                  }
                                >
                                  {({ ref, ...triggerHandler }) => (
                                    <a
                                      variant="light"
                                      {...triggerHandler}
                                      className="d-inline-flex align-items-center p-1"
                                    >
                                      <i
                                        ref={ref}
                                        className="fa-solid fa-circle-question"
                                      ></i>
                                    </a>
                                  )}
                                </OverlayTrigger>
                              </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="NumProcessInterno"
                              data-testid="NumProcessInterno"
                              onChange={(e) => {
                                handleChange(e);
                                setNumProcessInterno(e.target.value);
                              }}
                            />
                            <ErrorMessage component="p" name="NumProcessInterno" />
                          </FormGroup>

                          <FormGroup className="mb-3 p-1 formGroup ">
                            <label>
                              <span className="d-flex flex-row align-items-center">
                                Separar Itens por Lotes?
                              </span>
                            </label>
                            <Select
                              inputId="ItensLote"
                              name="ItensLote"
                              classNamePrefix="Selecione"
                              value={values.ItensLote}
                              placeholder="Selecione a área"
                              options={optionsLotes}
                              onChange={(selectedOptions) =>
                                handleLoteChange(selectedOptions, setFieldValue, values)
                              }
                            />
                            <ErrorMessage component="p" name="ItensLote" />
                          </FormGroup>
                        </div>

                        <div className=" d-flex gap-3">
                          <Button
                            className="btn btn-warning button"
                            type="button"
                            onClick={() => navigate("/")}
                          >
                            Voltar
                          </Button>
                          <Button
                            data-testid="button-numero-processo"
                            className="btn btn-primary button"
                            type="submit"
                          >
                            Salvar e Continuar
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NumeroProcesso;