import "react-quill/dist/quill.snow.css";
import { useEffect, useReducer, useState } from "react";
import AuthContext from "./contexts/auth";
import authReducer, { authReducerInitialValue } from "./reducers/auth";

import "./App.css";
import {
  SideNavigateContext,
  DataUserContext,
  CategoriesContext,
} from "./contexts/context";
import AppRoutes from "./routes/Routes";
import WithAxios from "./services/WhithAxios";
import { Decode } from "./services/Decode";
import { ReactSession } from "react-client-session";
function App() {
  const [state, dispatch] = useReducer(authReducer, authReducerInitialValue());
  const [userData, setUserData] = useState({});
  const [OpenSideNavigate, setOpenSideNavigate] = useState(true);
  const [categories, setCategories] = useState({});
  ReactSession.setStoreType("sessionStorage");
  async function loadStorage() {
    const token = ReactSession.get("token");

    if (!!token) {
      dispatch({
        type: "LOADED",
        payload: {
          token: token,
        },
      });

      setUserData(await Decode(token));
    }
  }
  useEffect(() => {
    loadStorage();
  }, [state.isAuthenticated]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <WithAxios>
        <CategoriesContext.Provider value={{ categories, setCategories }}>
          <DataUserContext.Provider value={{ userData, setUserData }}>
            <SideNavigateContext.Provider
              value={{ OpenSideNavigate, setOpenSideNavigate }}
            >
              <AppRoutes />
            </SideNavigateContext.Provider>
          </DataUserContext.Provider>
        </CategoriesContext.Provider>
      </WithAxios>
    </AuthContext.Provider>
  );
}

export default App;
