import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLotes } from '../services/lotesService';

export const getLotes = createAsyncThunk(
    'lotes/getLotes',
    async ({ token, code }, thunkAPI) => {
        try {
            const data = await fetchLotes(token, code);
            return data;
        } catch (error) {
            console.log('Error - Slice', error);
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const lotesSlice = createSlice({
    name: 'lotes',
    initialState: {
        dataLotes: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLotes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getLotes.fulfilled, (state, action) => {
                state.loading = false;
                state.dataLotes = action.payload;
            })
            .addCase(getLotes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default lotesSlice.reducer;
