import React, { useContext, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useLocation, useNavigate } from "react-router-dom";

import ReactQuill from "react-quill";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import { GetContentEtapa } from "../../../functions/Etapa/GetContentEtapa";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormGroup, Button } from "react-bootstrap";
import { GroupButtons } from "../../../components/GroupButons/GroupButtons";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalJustify } from "../../../components/ModalJustify/ModalJustify";
import { ModalPreViewEtapa } from "../../../components/ModalPreViewEtapa/ModalPreViewEtapa";
import { SkeletonEtapaEditor } from "../../../components/Skeletons/SkeletonEtapaEditor";
import AuthContext from "../../../contexts/auth";
import { GetCategories } from "../../../functions/Dominios/GetCategories";
import { searchTermo } from "../../../functions/ETP/searchTermo";
import { SubmitEtapa } from "../../../functions/Etapa/SubmitEtapa";
import { UpdateEtapa } from "../../../functions/Etapa/UpdateETapa";
import { DataUserContext } from "../../../contexts/context";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage/ModalErrorMessage";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";

const AcrescentarPerguntas = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => {
    if (!etapaIsJustify) {
      setShowEtapa(true);
    } else {
      setEtapaIsJustify(false);
    }
  };
  const [dataEditor, setDataEditor] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [request, setRequest] = useState({});
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [code, setCode] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const [etapaIsJustify, setEtapaIsJustify] = useState(false);
  const [load, setLoad] = useState(true);
  const [newInput, setNewInput] = useState(false);
  const [utilizaPerguntas, setUtilizaPerguntas] = useState(null);
  const [perguntas, setPerguntas] = useState(null);
  const { state } = useContext(AuthContext);
  const [etapa, setEtapa] = useState(null)
  const { userData } = useContext(DataUserContext);
  const MySwal = withReactContent(Swal);
  const [initialValues, setInitialValues] = useState({})
  
  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
    }
  }, []);

  useEffect(() => {
  }, [etapa]);

  async function getData(code) {
    try {
      const code = searchParams.get("code");
      GetContentEtapa(code, 11, state.token).then(async (contentEtapa) => {
        await setEtapa(contentEtapa)
      });
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleSubmit(event) {
    let data = {
      CodigoTermoReferencia: code,
      NumeroEtapa: 11,
      Descricao: JSON.stringify(event),
      EtapaPreenchida: true,
    };

    if (etapa) {
      data.Codigo = etapa.Codigo
      try{
        let ret = await UpdateEtapa(data, state.token, userData);
        // MySwal.fire("Tudo certo!", "Etapa alterada.", "success");
      }catch(e){
        ModalErrorMessage(e);
      }
      navigate(`/descricao-objeto?code=${code}`);
      return
    }

      try{
        let ret = await SubmitEtapa(data, state.token, userData);
        setEtapa(ret?.data)
        // MySwal.fire("Tudo certo!", "Etapa alterada.", "success");
      }catch(e){
        ModalErrorMessage(e);
      }
      navigate(`/descricao-objeto?code=${code}`);
  }

  async function getConfiguracao() {
    try{
      const response = await axios.get(`${(process.env.REACT_APP_BASEURL_CONFIGURACOES).replace(/\/$/, '')}/api/v1.0/ConfiguracoesAberta`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })

      let config = {}
      let pergs = []
      if(response?.data){
        await response?.data.map( async (item) => {
          if (item.Chave == 'Pergunta' && item.Status == 'Ativo') {
            pergs.push(item)
          }

          if (item.Chave == 'configuracao_cadastra_perguntas_tr') {
            config = item
          }

        })

        setUtilizaPerguntas(config)
        setPerguntas(pergs)

        setLoad(false)
      }

    } catch(error){
      ModalError(error);
    }
  }

  function handleJustify() {
    navigate(`/`);
  }

  useEffect(() => {
    getConfiguracao()
    getData()
  }, []);
  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={2} code={code} />
            {load ? (
              <SkeletonEtapaEditor />
            ) : (
              <div className="col-sm-9 mt-4">
                {
                  utilizaPerguntas.Valor == 'nao' ? (
                    <h2 className="text-center mt-4">Comprador não habilitado para esta etapa. Solicite ao administrador a alteração.</h2>
                  ) : <>
                        <Formik
                          onSubmit={handleSubmit}
                          enableReinitialize={true}
                          initialValues={etapa ? JSON.parse(etapa?.Descricao) : {}}>
                          <Form className="d-flex  flex-column mt-0">

                            {
                              perguntas.map( (item, index) => {
                                return item.Status == 'Ativo' &&
                                  <div key={index}>
                                    <h6 className="fw-bold">{item.Valor}</h6>
                                    <Field
                                      component="textarea"
                                      name={`resposta_${index}`}
                                      className="form-control h-100"
                                      rows="5"
                                      ></Field>
                                      <hr className="my-5 border-light" />
                                    </div>
                              })
                            }

                            <div className="row">
                              <div>

                                <Button className="m-2 btn_outline" type="submit">
                                  Salvar e continuar
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </>

                }

              </div>
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default AcrescentarPerguntas;
