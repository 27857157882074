/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";

import Navbar from "../../../components/Navbar/Navbar";

import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";

import { GetContentEtapa } from "../../../functions/Etapa/GetContentEtapa";

import { GroupButtons } from "../../../components/GroupButons/GroupButtons";
import { ModalPreViewEtapa } from "../../../components/ModalPreViewEtapa/ModalPreViewEtapa";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ListEtapas } from "../../../Dictionary/ListEtapas";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage/ModalErrorMessage";
import { SkeletonEtapaEditor } from "../../../components/Skeletons/SkeletonEtapaEditor";
import AuthContext from "../../../contexts/auth";
import { DataUserContext } from "../../../contexts/context";
import { SubmitEtapa } from "../../../functions/Etapa/SubmitEtapa";
import { UpdateEtapa } from "../../../functions/Etapa/UpdateETapa";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
import { ModalJustify } from "../../../components/ModalJustify/ModalJustify";


const CadastroEtapasDescricao = () => {
  const [show, setShow] = useState(false);
  const [dataEditor, setDataEditor] = useState("");
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  const handleShow = () => {
    setShow(true);
  };

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => {
    if (!etapaIsJustify) {
      setShowEtapa(true);
    } else {
      setEtapaIsJustify(false);
    }
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { state } = useContext(AuthContext);
  const { userData } = useContext(DataUserContext);

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [code, setCode] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const [etapaIsJustify, setEtapaIsJustify] = useState(false);
  const [dataEtapa, setDataEtapa] = useState({});
  const [nextEtapa, setNextEtapa] = useState({});
  const [newInput, setNewInput] = useState(false);
  const [changingInput, setChangingInput] = useState(false);

  const [errorMessage, setErrorMessage] = useState(""); // Estado para controlar a mensagem de erro

  async function getData(code, currentEtapa) {
    try {
      GetContentEtapa(code, currentEtapa - 4, state.token).then((contentEtapa) => {
        if (contentEtapa) {

          setContentCategory(contentEtapa);
          setEtapaIsJustify(!contentEtapa.EtapaPreenchida)
          setDataEditor(contentEtapa.Descricao);
          setNewInput(false);

        }

        if (!!!contentEtapa) {
          setNewInput(true);
          setEtapaIsJustify(false)
        }
      });
    } catch (error) {
      ModalErrorMessage(error);
    }
    setErrorMessage("");
  }
  async function InitEtapa(code) {
    const etapa = ListEtapas.filter((item) => {
      return item.route === location.pathname;
    });
    await getData(code, etapa[0].posit);

    const next = ListEtapas.filter((item) => {
      return item.posit === etapa[0].posit + 1;
    });

    setNextEtapa(next[0]);
    setDataEtapa(etapa[0]);
  }
  useEffect(() => {
    const code = searchParams.get("code");

    if (code !== null) {
      InitEtapa(code);
      setCode(code);
      setDataEditor("");
    }
  }, [location.pathname]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!dataEditor) {
      setErrorMessage("O campo de texto não pode estar vazio.");
    } else {
      try {
        if (newInput) {
          let data = {
            CodigoTermoReferencia: code,
            NumeroEtapa: dataEtapa.posit - 4,
            Descricao: dataEditor,
            EtapaPreenchida: etapaIsJustify ? false : true,
          };
          await SubmitEtapa(data, state.token, userData);
          setDataEditor("");
          setErrorMessage(""); // Limpa a mensagem de erro
          navigate(`${nextEtapa.route}?code=${code}`);
        } else {
          let data = {
            Codigo: contentCategory.Codigo,
            CodigoTermoReferencia: code,
            NumeroEtapa: dataEtapa.posit - 4,
            Descricao: dataEditor,
            EtapaPreenchida: etapaIsJustify ? false : true,
          };
          await UpdateEtapa(data, state.token, userData);
          setErrorMessage(""); // Limpa a mensagem de erro
        }
        setDataEditor("");
        setChangingInput(false)
        navigate(`${nextEtapa.route}?code=${code}`);
      } catch (error) {
        ModalErrorMessage(error);
      }
    }
  };

  const handleJustify = () => {
    setShowEtapa(false)
    setEtapaIsJustify(!etapaIsJustify);
    setErrorMessage(""); // Limpa a mensagem de erro ao alterar a etapa
  };

  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral
              ativo={dataEtapa.posit}
              code={code}
              navBlock={false}
            />
            {!(contentCategory || newInput) ? (
              <SkeletonEtapaEditor />
            ) : (
              <div className="col-sm-9 mt-4">
                {
                  etapaIsJustify && dataEditor &&
                  <div className={'alert alert-success'}>
                    Esta etapa está justificada
                  </div>
                }

                <div className="d-flex flex-row gap-2">
                  <h2>
                    {dataEtapa.name}
                    <small className={`d-block h6 mt-3 mb-0`}>{etapaIsJustify ? dataEtapa.justification : dataEtapa.description}:</small>

                  </h2>

                </div>
                {(dataEditor || newInput || changingInput) && (
                  <div className="editor mt-1 mb-5">
                    <ReactQuill
                      className="h-100"
                      name="text"
                      value={dataEditor}
                      onChange={(value) => {
                        setChangingInput(true)
                        if (removerTags(value)) {
                          setDataEditor(value);
                        } else {
                          setDataEditor("");
                        }
                      }}
                    />

                  </div>

                )}
                <div>
                  <small className={`d-block h6 mt-3 mb-0 text-danger`}>{errorMessage}</small>
                </div>
                <div className="row">
                  <GroupButtons
                    JustifyEtapa={etapaIsJustify}
                    handleSubmit={handleSubmit}
                    handleShow={handleShow}
                    handleShowEtapa={handleShowEtapa}
                  />


                  <ModalJustify
                    showEtapa={showEtapa}
                    handleCloseEtapa={handleCloseEtapa}
                    handleJustify={handleJustify}
                  />
                  <ModalPreViewEtapa setShow={setShow} showPreview={show} />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default CadastroEtapasDescricao;
