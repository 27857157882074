import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

const ModalPreviewDocumento = ({ show, handleClose, src }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const workerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Preview do Documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {src ? (
          <div style={{ height: '500px' }}>
            <Worker workerUrl={workerUrl}>
              <Viewer fileUrl={src} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          </div>
        ) : (
          <p>Talvez ele tenha sido movido, editado ou excluído.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPreviewDocumento;
