import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import anexoService from '../services/anexoService';

const initialState = {
    anexos: [],
    loading: false,
    error: null,
};

export const fetchAnexos = createAsyncThunk(
    'anexos/fetchAnexos',
    async ({ codigoTermoReferencia, token }, thunkAPI) => {
        try {
            const data = await anexoService.getAnexos(codigoTermoReferencia, token);
            return data.filter((item) => item.Status !== 'Deletado');
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const anexoSlice = createSlice({
    name: 'anexos',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnexos.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAnexos.fulfilled, (state, action) => {
                state.loading = false;
                state.anexos = action.payload.map((item) => ({
                    name: `${item?.Nome}.${item.Extensao}`,
                    size: item?.Tamanho,
                    codigoFile: item.Codigo,
                    CodigoTermoReferencia: item.CodigoTermoReferencia,
                    CodigoExterno: item.CodigoExterno,
                    Status: item.Status,
                }));
            })
            .addCase(fetchAnexos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default anexoSlice.reducer;
