import axios from "axios";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";

export async function GetContentEtapa(codigoTermo, codigoCategoria, token) {

  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  let rest = await axios.get(
    `${URL_API}/api/v1.0/Etapa?codigoTermoReferencia=${codigoTermo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  async function buscarPorNumero(numero) {
    for (const chave in EtapasApiCode) {
      if (EtapasApiCode[chave] === numero) {
        return chave;
      }
    }
    return null; // Retorna null se não encontrar correspondência
  }

  const etapaEncontrada = await buscarPorNumero(codigoCategoria);

  if (rest.data) {
    if (codigoCategoria === "all") {
      return rest.data;
    }

    const res = rest?.data?.filter(
      (item) => item.NumeroEtapa === etapaEncontrada
    );

    return res[0];
  }
}
