import React from "react";

import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalError } from "../ModalError/ModalError";
import { searchTermo } from "../../functions/ETP/searchTermo";
import { useLocation } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import axios from "axios";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import { GetContentEtapa } from "../../functions/Etapa/GetContentEtapa";
import { ModalErrorMessage } from "../ModalErrorMessage/ModalErrorMessage";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";
import { ListEtapas } from "../../Dictionary/ListEtapas";
import api from "../../services/api";
import { FormataMoeda } from "../../functions/FormataMoeda";
import { FormResponsible } from "../../functions/Responsible/FormResponsible";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Form, Formik } from "formik";

export function PreviewEtapa({ codigoTermo = null }) {
    const MySwal = withReactContent(Swal);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [DataTermo, setDataTermo] = useState({});
    const [code, setCode] = useState("");
    const { state } = useContext(AuthContext);
    const [DataLotes, setDataLotes] = useState([]);
    const [DataEtapas, setDataEtapas] = useState([]);
    const [unidadeCompradora, setUnidadeCompradora] = useState([{}]);
    const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [Requisitante, setRequisitante] = useState("");
    const [DataItem, setDataItem] = useState([]);
    const [termoReferencia, setTermoReferencia] = useState({});
    const [load, setLoad] = useState();
    const [utilizaPerguntas, setUtilizaPerguntas] = useState(null);
    const [perguntas, setPerguntas] = useState(null);
    const [etapa, setEtapa] = useState(null);
    const [fields, setFields] = useState([]);
    const [init, setInit] = useState({});
    const [valueRequester, setValueRequester] = useState([]);
    const [responsaveis, setResponsaveis] = useState([{}]);

    async function getData(code) {
        try {
            const code = searchParams.get("code");
            GetContentEtapa(code, 11, state.token).then(async (contentEtapa) => {
                await setEtapa(contentEtapa);
            });
            await searchTermo(state.token, code).then((result) => {
                if (result) {
                    setTermoReferencia(result);
                    setDataTermo(result);
                    setRequisitante(result.CodigoUnidadeRequisitante);
                    getDetalhesItem(result.Codigo);
                    setResponsaveis(result.Responsaveis);
                }
            });
        } catch (error) {
            ModalError(error);
        }
    }

    function getDetalhesItem(codigoTermo) {
        try {
            axios
                .get(
                    `${URL_API}/api/v1.0/material-servico?codigoTermoReferencia=${codigoTermo}`,
                    {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        setDataItem(res.data);
                    }
                    setLoad(true);
                });
        } catch (error) { }
    }

    function getLotes(code) {
        try {
            axios
                .get(
                    `${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${code}`,
                    {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        setDataLotes(res.data);
                    }
                });
        } catch (error) { }
    }

    async function getDataAnexo(code) {
        try {
            api
                .get(`/api/v1.0/anexos?codigoTermoReferencia=${code}`, {
                    headers: {
                        Authorization: `Bearer ${state.token}`,
                    },
                })
                .then((res) => {
                    if (res.data) {
                        let dataRes = res.data
                            .filter((item) => item.Status !== "Deletado")
                            .map((item) => ({
                                name: item?.Nome + "." + item.Extensao,
                                size: item?.Tamanho,
                                codigoFile: item.Codigo,
                                CodigoTermoReferencia: item.CodigoTermoReferencia,
                                CodigoExterno: item.CodigoExterno,
                                Status: item.Status,
                            }));

                        setSelectedFiles(dataRes);
                    }
                });
        } catch (error) { }
    }

    async function getEtapas(code, currentEtapa) {
        try {
            GetContentEtapa(code, currentEtapa, state.token).then((contentEtapa) => {
                if (contentEtapa) {
                    const listaMatch = contentEtapa
                        .map((item) => EtapasApiCode[item.NumeroEtapa])
                        .filter((numero) => numero !== undefined)
                        .map((numero) => {
                            return {
                                numero: numero,
                                content: contentEtapa.find(
                                    (item) => EtapasApiCode[item.NumeroEtapa] === numero
                                ).Descricao,
                                justificada: contentEtapa.find(
                                    (item) => {
                                        return EtapasApiCode[item.NumeroEtapa] === numero;
                                    }
                                ).EtapaPreenchida,
                            };
                        });

                    const temp = listaMatch.map((valor) => {
                        const etapaCorrespondente = ListEtapas.find(
                            (etapa) => etapa.posit === valor.numero + 4
                        );
                        return {
                            ...valor,
                            nome: etapaCorrespondente ? etapaCorrespondente.name : undefined,
                        };
                    });

                    setDataEtapas(temp);
                }
            });
        } catch (error) {
            ModalErrorMessage(error);
        }
    }

    function getUnidadeCompradora() {
        try {
            axios
                .get(
                    `${process.env.REACT_APP_COMPRADOR}/v1/comprador/unidades-compradora-usuario`,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "*",

                            Authorization: `Bearer ${state.token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        const values = res?.data?.map((item) => {
                            return { label: item.nome, value: item.codigo };
                        });
                        setUnidadeCompradora(values);
                    }
                });
        } catch (error) {
            ModalError(error);
        }
    }

    async function getConfiguracao() {
        try {
            const response = await axios.get(`${(process.env.REACT_APP_BASEURL_CONFIGURACOES).replace(/\/$/, '')}/api/v1.0/ConfiguracoesAberta`,
                {
                    headers: {
                        Authorization: `Bearer ${state.token}`,
                    },
                });

            let config = {};
            let pergs = [];
            if (response?.data) {
                await response?.data.map(async (item) => {
                    if (item.Chave === 'Pergunta' && item.Status === 'Ativo') {
                        pergs.push(item);
                    }

                    if (item.Chave === 'configuracao_cadastra_perguntas_tr') {
                        config = item;
                    }
                });

                setUtilizaPerguntas(config);
                setPerguntas(pergs);

                setLoad(false);
            }
        } catch (error) {
            ModalError(error);
        }
    }

    useEffect(() => {
        getConfiguracao();
    }, []);

    function RemoveItem(key) {
        MySwal.fire({
            title: "Tem certeza?",
            text: "Você não será capaz de reverter isso!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, exclua!",
        }).then((result) => {
            if (result.isConfirmed) {
                setFields((prevFields) => {
                    const updatedFields = prevFields.filter((item) => {
                        if (item.key === `${key}`) {
                            removeItemApi(document.getElementById(`code-${item.key}`).value);
                        }

                        return item.key !== `${key}`;
                    });
                    return updatedFields;
                });
                MySwal.fire("Excluído!", "O responsável foi deletado.", "success");
            }
        });
    }

    async function removeItemApi(code) {
        try {
            await api.delete(`/api/v1.0/responsaveis/${code}`, {
                headers: {
                    Authorization: `Bearer ${state.token}`,
                    unidade_compradora: unidadeCompradora,
                },
            });
        } catch (error) {
            ModalError(error);
        }
    }

    async function getResponsavel(code) {
        try {
            await searchTermo(state.token, code).then((result) => {
                if (result) {
                    setUnidadeCompradora(result.CodigoUnidadeRequisitante);
                    axios.get(`${URL_API}/api/v1.0/responsaveis?codigoTermoReferencia=${code}`, {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                            unidade_compradora: result.CodigoUnidadeRequisitante,
                        },
                    })
                        .then((res) => {
                            if (res.data) {
                                const filteredData = res.data.filter(
                                    (item) => item.Status !== "Deletado"
                                );
                                if (filteredData.length === 0) {
                                    setFields([FormResponsible(0, () => RemoveItem(0))]);
                                }
                                setValueRequester(filteredData);

                                const initialValues = filteredData.reverse().reduce(
                                    (acc, curr, index) => {
                                        return {
                                            ...acc,
                                            [`code-${index}`]: curr.Codigo,
                                            [`responsavel-${index}`]: curr.Nome,
                                            [`cargo-${index}`]: curr.Cargo,
                                            [`cpf-${index}`]: curr.CPF,
                                        };
                                    },
                                    {}
                                );
                                setInit(initialValues);
                            }
                            setLoad(true);
                        });
                }
            });
        } catch (error) {
            ModalError(error);
        }
    }

    useEffect(() => {
        const code = searchParams.get("code");
        if (code !== null || codigoTermo) {
            setCode(codigoTermo ?? code);
            getDataAnexo(codigoTermo ?? code);
            getEtapas(codigoTermo ?? code, "all");
            getLotes(codigoTermo ?? code);
            getData(codigoTermo ?? code);
            getUnidadeCompradora();
        }
    }, []);

    return (
        <div>
            <div className="rowContainer pb-2" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="col-6">
                    <div className="textContainer">
                        <div>
                            <p>Criado em: </p>
                            &nbsp;{dayjs(DataTermo?.Criado).format("DD/MM/YYYY")}
                        </div>
                        {DataTermo?.Editado && (
                            <div>
                                <p>Editado em:</p>
                                &nbsp;{dayjs(DataTermo?.Editado)?.format("DD/MM/YYYY")}
                            </div>
                        )}
                    </div>
                    <div className="textContainer">
                        <div>
                            <p>Responsável:</p>
                            &nbsp;
                            {termoReferencia.NomesResponsaveis && termoReferencia.NomesResponsaveis.length > 0 ? (
                                termoReferencia.NomesResponsaveis.map((nome, index) => (
                                    <span key={index}>{nome}{index < termoReferencia.NomesResponsaveis.length - 1 && ','} &nbsp;</span>
                                ))
                            ) : (
                                <span>Nenhum responsável adicionado</span>
                            )}
                        </div>
                    </div>

                    <div className="textContainer">
                        <div>
                            <p>Número do Termo de Referência: </p>
                            &nbsp;{DataTermo?.CodigoTermoReferencia}
                        </div>
                        <div>
                            <p>Área Requisitante: </p>
                            &nbsp;{Requisitante
                                ? unidadeCompradora?.find((item) => {
                                    return item.value === parseInt(Requisitante);
                                })?.label
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="textContainer">
                        <div>
                            <p>Itens separado por lote?</p>
                            &nbsp;{termoReferencia.SepararItensPorLote ? "Sim" : "Não"}
                        </div>
                    </div>
                    {termoReferencia.SepararItensPorLote ? (
                        <div className="textContainer">
                            <div>
                                <p>Forma de disputa por lote:</p>
                                &nbsp;{termoReferencia.FormaDisputaLote}
                            </div>
                        </div>
                    ) : ""}

                </div>

            </div>

            <div className="rowContainer">
                <div className="textContainer mt-3">
                    <div>
                        <h5>Perguntas</h5>
                    </div>
                    {perguntas && perguntas.length > 0 ? (
                        perguntas.map((item, index) => (
                            item.Status === 'Ativo' && (
                                <React.Fragment key={index}>
                                    <div>
                                        <h6 className="fw-bold">{item.Valor}</h6>
                                    </div>
                                    <p key={`resposta-${index}`}>
                                        {etapa && JSON.parse(etapa.Descricao)?.[`resposta_${index}`] || "Não foi encontrada resposta para essa pergunta"}
                                    </p>
                                </React.Fragment>
                            )
                        ))
                    ) : (
                        <p>Sem perguntas disponíveis</p>
                    )}
                </div>
            </div>

            <div className="rowContainer" >
                <div className="textContainer mt-3">
                    <div>
                        <h5>Descrição do objeto :</h5>
                    </div>
                </div>
                <p>
                    {removerTags(DataTermo?.DescricaoObjeto || "Nenhuma descrição foi adicionada")}
                </p>
            </div>

            <div className="rowContainer">
                <h5 className="mt-2">Detalhamento do Itens</h5>

                {termoReferencia.SepararItensPorLote && DataLotes?.map((Lote, loteIndex) => (
                    <div key={loteIndex} className="container-Lote mt-3">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Lote</th>
                                    <th>Descrição</th>
                                    <th>Finalizado</th>
                                    <th>Valor Total</th>
                                </tr>
                                <tr>
                                    <td>{loteIndex + 1}</td>
                                    <td>{removerTags(Lote?.DescricaoLote)}</td>
                                    <td>{Lote?.Finalizado ? "Sim" : "Não"}</td>
                                    <td>{FormataMoeda(Lote?.ValorTotal)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table mb-5">
                            <tbody>
                                <tr>
                                    <th>Item</th>
                                    <th>Descrição</th>
                                    <th>Un. Medida</th>
                                    <th>Quantidade</th>
                                    <th>Valor Unitário</th>
                                    <th>Total</th>
                                </tr>

                                {Lote.MateriaisServicos.map((MateriaisServicos, itemIndex) => (
                                    <tr key={itemIndex}>
                                        <td>{itemIndex + 1}</td>
                                        <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                            {removerTags(MateriaisServicos.DescricaoObjeto)}
                                        </td>
                                        <td>{MateriaisServicos.UnidadeMedida}</td>
                                        <td>{MateriaisServicos.Quantidade}</td>
                                        <td>{FormataMoeda(MateriaisServicos.ValorUnitario)}</td>
                                        <td>{FormataMoeda(MateriaisServicos.ValorUnitario * MateriaisServicos.Quantidade)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}

                {DataItem?.length > 0 && !termoReferencia.SepararItensPorLote && (
                    <table className="table mb-5">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Descrição</th>
                                <th>Un. Medida</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DataItem.map((i, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                        {removerTags(i.DescricaoObjeto)}
                                    </td>
                                    <td>{i.UnidadeMedida}</td>
                                    <td>{i.Quantidade}</td>
                                    <td>{FormataMoeda(i.ValorUnitario)}</td>
                                    <td>{FormataMoeda(i.ValorUnitario * i.Quantidade)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {DataEtapas?.map((item, index) => {
                return (
                    <div key={index} className="rowContainer mt-3">
                        <div className="textContainer">
                            {!item.justificada && (
                                <div className={'alert alert-success w-100'}>
                                    Esta etapa está justificada
                                </div>
                            )}
                            <div>
                                <h5>{item.nome}</h5>
                            </div>
                            <div className="mb-3 mt-1">
                                <p>{item.justificada ? "Descrição" : "justificativa"}:</p>
                                &nbsp;{removerTags(item.content)}
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className="rowContainer">
                <h5 className="mt-2">Responsáveis</h5>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Responsável</th>
                            <th className="text-center">CPF</th>
                            <th className="text-center">Cargo</th>
                        </tr>
                        {responsaveis?.map((responsavel, index) => (
                            <tr key={index}>
                                <td>{responsavel.Nome}</td>
                                <td className="text-center">{responsavel.CPF}</td>
                                <td className="text-center">{responsavel.Cargo}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div>
                <h5 className="mt-2">Anexos</h5>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Número</th>
                            <th className="text-center">Nome</th>
                            <th className="text-center">Tamanho do Arquivo</th>
                        </tr>
                        {selectedFiles.map((file, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="text-center">{file.name}</td>
                                <td className="text-center">{file.size}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
