import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../contexts/auth";
// import { FormatBytes } from "../../functions/FormatBytes";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";
import { ListEtapas } from "../../Dictionary/ListEtapas";
import { GetContentEtapa } from "../../functions/Etapa/GetContentEtapa";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import api from "../../services/api";
import { ModalErrorMessage } from "../ModalErrorMessage/ModalErrorMessage";
import "./ModalDocumento.scss";
import { FormataMoeda } from "../../functions/FormataMoeda";
import { searchTermo } from "../../functions/ETP/searchTermo";
import { ModalError } from "../ModalError/ModalError";
export function ModalDetail({
  showModal,
  handleCloseModal,
  itemTr,
  solicitacoes = false,
}) {
  const initialValues = {
    selecao: "",
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const [ItemData, setItemData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [EtapasDescricao, setEtapasDescricao] = useState([]);
  const [DataLotes, setDataLotes] = useState([]);
  const [termoReferencia, setTermoReferencia] = useState({});
  const [DataItem, setDataItem] = useState([]);
  const [Requisitante, setRequisitante] = useState("");
  const [load, setLoad] = useState();
  const [etapa, setEtapa] = useState(null);
  const [responsaveis, setResponsaveis] = useState([{}]);
  const [DataTermo, setDataTermo] = useState({});
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  async function getDataAnexo(code) {
    try {
      api
        .get(`/api/v1.0/anexos?codigoTermoReferencia=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let dataRes = res.data
              .filter((item) => item.Status !== "Deletado")
              .map((item) => ({
                name: item?.Nome + "." + item.Extensao,
                size: item?.Tamanho,
                codigoFile: item.Codigo,
                CodigoTermoReferencia: item.CodigoTermoReferencia,
                CodigoExterno: item.CodigoExterno,
                Status: item.Status,
              }));

            setSelectedFiles(dataRes);
          }
        });
    } catch (error) { }
  }

  function buscarNumero(codigo) {
    for (const chave in EtapasApiCode) {
      if (EtapasApiCode[chave] === codigo) {
        return chave;
      }
    }
    return null; // Retorna null se não encontrar correspondência
  }
  function getLotes(codigoTermo) {
    try {
      axios
        .get(
          `${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${codigoTermo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setDataLotes(res.data);
          }
        });
    } catch (error) { }
  }
  async function getData(code, currentEtapa) {
    try {
      GetContentEtapa(code, currentEtapa, state.token).then((contentEtapa) => {
        if (contentEtapa) {
          const listaMatch = contentEtapa
            .map((item) => EtapasApiCode[item.NumeroEtapa])
            .filter((numero) => numero !== undefined)
            .map((numero) => {
              return {
                numero: numero,
                content: contentEtapa.find(
                  (item) => EtapasApiCode[item.NumeroEtapa] === numero
                ).Descricao,
              };
            });

          const temp = listaMatch.map((valor) => {
            const etapaCorrespondente = ListEtapas.find(
              (etapa) => etapa.posit === valor.numero + 4
            );
            return {
              ...valor,
              nome: etapaCorrespondente ? etapaCorrespondente.name : undefined,
            };
          });

          setEtapasDescricao(temp);
        }
      });
      await searchTermo(state.token, code).then((result) => {
        if (result) {
          setTermoReferencia(result);
          setDataTermo(result);
          setRequisitante(result.CodigoUnidadeRequisitante);
          getDetalhesItem(result.Codigo);
          setResponsaveis(result.Responsaveis);
        }
      });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  function getDetalhesItem(codigoTermo) {
    try {
      axios
        .get(
          `${URL_API}/api/v1.0/material-servico?codigoTermoReferencia=${codigoTermo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setDataItem(res.data);
          }
          setLoad(true);
        });
    } catch (error) { }
  }


  useEffect(() => {
    if (itemTr) {
      getDataAnexo(itemTr?.Codigo);
      setItemData(itemTr);
      getData(itemTr?.Codigo, "all");
      getLotes(itemTr?.Codigo);
    }
  }, [itemTr]);

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">
          Detalhes do Termo de Referência
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 ">
          <div className="row w-100">
            <div className="">
              <div>
                <div className="rowContainerModal">
                  <table className="table" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                    <tbody>
                      <tr>
                        <td style={{ border: 'none', padding: '8px' }}>
                          <div className="textContainer">
                            <div>
                              <p>Código do Termo de Referência:</p>
                            </div>
                          </div>
                        </td>
                        <td style={{ border: 'none', padding: '8px' }}>{ItemData?.CodigoTermoReferencia}</td>
                      </tr>
                      <tr>
                        <td style={{ border: 'none', padding: '8px' }}>
                          <div className="textContainer">
                            <div>
                              <p>Descrição:</p>
                            </div>
                          </div>
                        </td>
                        <td style={{ border: 'none', padding: '8px' }}>
                          <div dangerouslySetInnerHTML={{ __html: ItemData?.DescricaoObjeto }} />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: 'none', padding: '8px' }}>
                          <div className="textContainer">
                            <div>
                              <p>Número do Processo Interno:</p>
                            </div>
                          </div>
                        </td>
                        <td style={{ border: 'none', padding: '8px' }}>{ItemData?.NumeroProcessoInterno}</td>
                      </tr>
                      <tr>
                        <td style={{ border: 'none', padding: '8px' }}>
                          <div className="textContainer">
                            <div>
                              <p>Separar Itens Por Lote:</p>
                            </div>
                          </div>
                        </td>
                        <td style={{ border: 'none', padding: '8px' }}>{ItemData?.SepararItensPorLote ? "sim" : "não"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {DataLotes && <h5 className="mt-2">Detalhamento do Itens</h5>}
              {/* {DataLotes?.map((Lote, index) => {
                return (
                  <div key={index} className="container-Lote mt-3">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Lote</th>
                          <th>Descrição</th>
                          <th>Finalizado</th>
                          <th>Valor Total</th>
                        </tr>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{removerTags(Lote?.DescricaoLote)}</td>

                          <td>{Lote?.Finalizado ? "Sim" : "Não"}</td>
                          <td>{Lote?.ValorTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                    {Lote.MateriaisServicos.map((MateriaisServicos, i) => {
                      return (
                        <table key={i} className="table">
                          <tbody>
                            <tr>
                              <th>Item</th>
                              <th>Descrição</th>
                              <th>Un. Medida</th>
                              <th>Quantidade</th>
                              <th>Valor Unitário</th>
                              <th>Total</th>
                            </tr>

                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {removerTags(MateriaisServicos.DescricaoObjeto)}
                              </td>
                              <td> {MateriaisServicos.UnidadeMedida}</td>
                              <td>{MateriaisServicos.Quantidade}</td>
                              <td>{MateriaisServicos.ValorUnitario}</td>
                              <td>
                                {MateriaisServicos.ValorUnitario *
                                  MateriaisServicos.Quantidade}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                );
              })} */}

              {termoReferencia.SepararItensPorLote && DataLotes?.map((Lote, loteIndex) => (
                <div key={loteIndex} className="container-Lote mt-3">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Lote</th>
                        <th>Descrição</th>
                        <th>Finalizado</th>
                        <th>Valor Total</th>
                      </tr>
                      <tr>
                        <td>{loteIndex + 1}</td>
                        <td>{removerTags(Lote?.DescricaoLote)}</td>
                        <td>{Lote?.Finalizado ? "Sim" : "Não"}</td>
                        <td>{FormataMoeda(Lote?.ValorTotal)}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table mb-5">
                    <tbody>
                      <tr>
                        <th>Item</th>
                        <th>Descrição</th>
                        <th>Un. Medida</th>
                        <th>Quantidade</th>
                        <th>Valor Unitário</th>
                        <th>Total</th>
                      </tr>

                      {Lote.MateriaisServicos.map((MateriaisServicos, itemIndex) => (
                        <tr key={itemIndex}>
                          <td>{itemIndex + 1}</td>
                          <td style={{ maxWidth: 350, overflow: 'auto' }}>
                            {removerTags(MateriaisServicos.DescricaoObjeto)}
                          </td>
                          <td>{MateriaisServicos.UnidadeMedida}</td>
                          <td>{MateriaisServicos.Quantidade}</td>
                          <td>{FormataMoeda(MateriaisServicos.ValorUnitario)}</td>
                          <td>{FormataMoeda(MateriaisServicos.ValorUnitario * MateriaisServicos.Quantidade)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}

              {DataItem?.length > 0 && !termoReferencia.SepararItensPorLote && (
                <table className="table mb-5">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Descrição</th>
                      <th>Un. Medida</th>
                      <th>Quantidade</th>
                      <th>Valor Unitário</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DataItem.map((i, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ maxWidth: 350, overflow: 'auto' }}>
                          {removerTags(i.DescricaoObjeto)}
                        </td>
                        <td>{i.UnidadeMedida}</td>
                        <td>{i.Quantidade}</td>
                        <td>{FormataMoeda(i.ValorUnitario)}</td>
                        <td>{FormataMoeda(i.ValorUnitario * i.Quantidade)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {EtapasDescricao?.map((item, index) => {
                return (
                  <div key={index} className="rowContainer">
                    <div className="textContainer">
                      <div>
                        <p>Etapa:</p>
                        {item.nome}
                      </div>
                      <div>
                        <p>Descrição:</p>
                        {removerTags(item.content)}
                      </div>
                    </div>
                  </div>
                );
              })}
              <h5 className="mt-2">Anexos</h5>
              <table className="table">
                <tbody>
                  <tr className="">
                    <th>Número</th>
                    <th className="text-center">Nome</th>
                    <th className="text-center">Tamanho do Arquivo</th>
                  </tr>
                  {selectedFiles.map((file, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-center">{file.name}</td>
                      <td className="text-center">{file.size}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
