import React from "react";
import { Button, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
export function GroupButtons({
  handleSubmit,
  handleShow,
  handleShowEtapa,
  JustifyEtapa = false,
}) {
  return (
    <>
    <div className="col-md-6">
        <Nav onClick={handleShowEtapa}>
          <Nav.Item>
            <Nav.Link eventKey="link-1">
              <div className="link">
                {JustifyEtapa
                  ? "Preencher esta etapa"
                  : "Não preencher esta etapa"}{" "}
              </div>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div>
        <Button
          className="btn btn-primary m-2"
          type="submit"
          onClick={handleSubmit}
        >
          Salvar e Continuar
        </Button>
        <Button className="m-2 btn_outline" onClick={handleShow}>
          Visualizar
        </Button>
      </div>
    </>
  );
}
