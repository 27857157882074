export const EtapasApiCode = {
	PrazoContratoPossivelProrrogacao : 1,
	FundamentacaoContratacao : 2,
	DescricaoSolucao : 3,
	RequisitosContratacao : 4,
	ModeloExecucaoObjeto : 5,
	ModeloGestaoContrato : 6,
	CriteriosMedicaoPagamento : 7,
	CriteriosSelecaoFornecedor : 8,
	EstimativaValorContratacao : 9,
	AdequacaoOrcamentaria : 10
}