import Placeholder from "react-bootstrap/Placeholder";
export function SkeletonEtapaEditor() {
  return (
    <div className="col-sm-9 mt-4">
      <Placeholder as="h2" animation="glow">
        <Placeholder style={{ width: "80%" }} size="lg" bg="secondary" />
      </Placeholder>

      <Placeholder as="div" animation="glow" style={{ marginTop: "10px" }}>
        <Placeholder
          style={{ width: "100%", height: "280px" }}
          size="lg"
          bg="secondary"
        />
      </Placeholder>
      {/* { (contentCategory|| newInput ) && <CKeditorEtp text={contentCategory} setDataEditor={setDataEditor}/>} */}

      <div className="row">
        <div className="col-md">
          <Placeholder animation="glow" style={{ margin: "0px" }}>
            <Placeholder style={{ width: "30%" }} size="lg" bg="secondary" />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "28%" }}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "10px" }}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
        {/* <GroupButtons handleSubmit={handleSubmit} handleShow={handleShow} handleShowEtapa={handleShowEtapa} /> */}
        {/* Modal Preencher etapa */}

        {/* Modal Visualização */}
        {/* <ModalPreViewEtapa 
            showPreview={show} 
            setShow={setShow}
            title="Descrição da Necessidade"
            body={dataEditor}
            /> */}

        {/* <ModalJustify showEtapa={showEtapa} handleCloseEtapa={handleCloseEtapa} handleJustify={handleJustify} /> */}
      </div>
    </div>
  );
}
