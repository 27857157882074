import { API_DOCUMENTOS, requestConfig } from "../utils/config";

// Obter Documentos
const getDocumento = async (data) => {
    const config = requestConfig('GET', data);
    try {
        const response = await fetch(`${API_DOCUMENTOS}/Documento`, config);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Obter Metadados
const getMetadados = async (data) => {
    const config = requestConfig('GET', data);
    try {
        const response = await fetch(`${API_DOCUMENTOS}/MetaDado`, config);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Obter Modelo
const getModelo = async (data) => {
    const config = requestConfig('GET', data);
    try {
        const response = await fetch(`${API_DOCUMENTOS}/Modelo`, config);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Obter Tipo Documento
const getTipoDocumento = async (data) => {
    const config = requestConfig('GET', data);
    try {
        const response = await fetch(`${API_DOCUMENTOS}/TipoDocumento/ObterTodos`, config);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Gerar Documento
const gerarDocumento = async (data) => {
    const config = requestConfig('POST', data);
    try {
        const response = await fetch(`${API_DOCUMENTOS}/Documento/GerarDocumento`, config);
        const responseData = await response.json();
        if (responseData.error) {
            throw new Error(responseData.message);
        }
        return {
            message: "Documento Gerado com Sucesso!",
            base64: responseData 
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};



const documentosService = {
    getDocumento,
    getMetadados,
    getModelo,
    getTipoDocumento,
    gerarDocumento
};

export default documentosService;
