import "./ModalServico.scss";
import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../contexts/auth";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
export function ModalServico({ show, setShow, setSelectItem }) {
  const { state, dispatch } = useContext(AuthContext);
  const handleClose = () => setShow(false);
  const MySwal = withReactContent(Swal);
  const [RequestMateriais, setRequestMateriais] = useState([]);
  const [UnidadeDeMedida, setUnidadeDeMedida] = useState([]);
  async function getData() {
    try {
      getDataUnidade()
      const response = await axios.get(
        `${(process.env.REACT_APP_CMS).replace(/\/$/, '')}/api/v1.0/servicos/servicos`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestMateriais(response.data);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataUnidade() {
   
    try {
      const response = await axios.get(
        `${(process.env.REACT_APP_CMS).replace(/\/$/, '')}/api/v1.0/materiais/unidades-de-medida`,
        {
          headers: {
            unidade_compradora: 227,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setUnidadeDeMedida(filtro);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  function SelectItem(material) {
    material.UnidadeMedidaText = material?.UnidadeMedida?.Nome;
    setSelectItem(material);
    handleClose();
  }
  const memoData = useMemo(() => getData, [show]);
  useEffect(() => {
    if (show) {
      memoData();
    }
  }, [show, memoData]);
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Serviços</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {RequestMateriais.map((material, index) => {
            return (
              <div className="list-Material row p-4 m-2 align-items-end" key={index}>
                <div className="col-sm-12">
                  <h5>{material.Nome}</h5>
                  <table className={'table border-0'}>
                    <tr className={'border-0'}>
                      <td width={150}  className={'border-0'}>
                        <span>Código</span>
                      </td>
                      <td className={'border-0'}>
                        <p>{material?.CodigoServico}</p>
                      </td>
                    </tr>
                    <tr className={'border-0'}  style={{verticalAlign: 'top'}}>
                      <td className={'border-0'} width={150}>
                        <span>Descrição</span>
                      </td>
                      <td  className={'border-0'}  style={{verticalAlign: 'top'}}>
                        <p style={{wordBreak: 'break-all'}}>{removerTags(material?.InformacoesAdicionais)}</p>
                      </td>
                    </tr>

                    <tr className={'border-0'}  style={{verticalAlign: 'top'}}>
                      <td className={'border-0'} width={150}>
                        <span>Classe</span>
                      </td>
                      <td  className={'border-0'}  style={{verticalAlign: 'top'}}>
                        <p style={{wordBreak: 'break-all'}}>{material?.Classe?.Nome}</p>
                      </td>
                    </tr>

                    <tr className={'border-0'}  style={{verticalAlign: 'top'}}>
                      <td className={'border-0'} width={150}>
                        <span>Subclasse</span>
                      </td>
                      <td  className={'border-0'}  style={{verticalAlign: 'top'}}>
                        <p style={{wordBreak: 'break-all'}}>{material?.Subclasse?.Nome}</p>
                      </td>
                    </tr>
                  </table>
                  
                </div>
                <div className="col-sm-5">
                  <Button onClick={() => SelectItem(material)}>
                    Selecionar
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
