import axios from "axios";

export async function searchTermo(token, code = "") {

  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  let rest = await axios.get(
    `${URL_API}/api/v1.0/termo-referencia`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (code !== "") {
    if (rest.data) {
      const res = rest.data.filter((item) => item.Codigo === code);

      return res[0];
    }
  } else {
    return rest.data;
  }
}
