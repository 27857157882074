import axios from "axios";


export async function UpdateEtapa(data, token,unidade) {
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')

  let rest = await axios.put(
    `${URL_API}/api/v1.0/Etapa`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        unidade_compradora:unidade
      },
    }
  );
  return rest;
}
