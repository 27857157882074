import Placeholder from "react-bootstrap/Placeholder";
export function DetalhesItemSkeleton() {
  return (
    <div className="col-sm-9 mt-4">
      <Placeholder as="h2" animation="glow">
        <Placeholder style={{ width: "80%" }} size="lg" bg="secondary" />
      </Placeholder>

      <Placeholder as="div" animation="glow" style={{ marginTop: "10px" }}>
        <Placeholder
          style={{ width: "100%", height: "280px" }}
          size="lg"
          bg="secondary"
        />
      </Placeholder>
      {/* { (contentCategory|| newInput ) && <CKeditorEtp text={contentCategory} setDataEditor={setDataEditor}/>} */}

      <div className="col">
        <div className="col-md">
          <Placeholder animation="glow" style={{}}>
            <Placeholder
              style={{ width: "23%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "10px" }}>
            <Placeholder
              style={{ width: "23%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "10px" }}>
            <Placeholder
              style={{ width: "23%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "10px" }}>
            <Placeholder
              style={{ width: "23%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
        <div className=" col-md ">
          <Placeholder animation="glow" style={{}}>
            <Placeholder
              style={{ width: "45%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "10px" }}>
            <Placeholder
              style={{ width: "50%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "25%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
      </div>
    </div>
  );
}
