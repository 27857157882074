import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import styles from "./DetalhamentoItens.scss";

import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthContext from "../../../contexts/auth";
import api from "../../../services/api";

import ReactQuill from "react-quill";

import axios from "axios";
import { ModalEditarLote } from "../../../components/ModalEditarLote/ModalEditarLote";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage/ModalErrorMessage";
import { ModalMaterial } from "../../../components/ModalMateriais/ModalMateriais";
import { ModalServico } from "../../../components/ModalServico/ModalServico";
import { DetalhesItemSkeleton } from "../../../components/Skeletons/DetalhesItemSkeleton";
import { searchTermo } from "../../../functions/ETP/searchTermo";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
import { FormataMoeda } from "../../../functions/FormataMoeda";
import { CalculaValorTotalLote } from "../../../functions/CalculaValorTotalLote";


export function DetalhamentoItens() {
  const { state } = useContext(AuthContext);
  const [DescricaoLote, SetDescricaoLote] = useState("");
  const [CurrentLote, setCurrentLote] = useState(0);
  const [showEditarLote, setShowEditarLote] = useState(false);
  const [showMaterial, setShowMaterial] = useState(false);
  const [showServico, setShowServico] = useState(false);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => setShowEtapa(true);
  const [code, setCode] = useState("");
  const [dataEditor, setDataEditor] = useState("");
  const [DataItem, setDataItem] = useState([]);
  const [DataLotes, setDataLotes] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [selectCatalogo, setSelectCatalogo] = useState({
    value: 1,
    label: "Material",
  });
  const [CodigoExterno, setCodigoExterno] = useState("");
  const [ValorUnitario, setValorUnitario] = useState(0);
  const [Quantidade, setQuantidade] = useState(1);
  const [NaturezaItem, setNaturezaItem] = useState();
  const [CodigoItem, setCodigoItem] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [totalLoteValue, setTotalLoteValue] = useState(0);

  // const [currentLoteList, setCurrentLoteList] = useState(0);
  const [load, setLoad] = useState(false);

  const [selectMaterial, setSelectMaterial] = useState({});
  const [selectServico, setSelectServico] = useState({});
  const [termoReferencia, setTermoReferencia] = useState({});
  const [newInput, setNewInput] = useState(true);
  const [updateValue, setUpdateValue] = useState(false);
  const [currentItemUpdate, setCurrentItemUpdate] = useState("");

  const MySwal = withReactContent(Swal);
  const [usaCatalogo, setUsaCatalogo] = useState(false);
  const URL_API = process.env.REACT_APP_BASEURL.replace(/\/$/, "");
  const [UnidadeDeMedidaServiceOptions, setUnidadeDeMedidaServiceOptions] = useState([]);
  const [UnidadeMedidaMaterialOptions, setUnidadeMedidaMaterialOptions] = useState([]);
  const [UnidadeMedidaSelect, setUnidadeMedidaSelect] = useState({});
  const [currentIndexList, setCurrentIndexList] = useState(0);
  const [UnidadeMedidaText, setUnidadeMedidaText] = useState("");

  const [currentLoteIndex, setCurrentLoteIndex] = useState(0);
  const [totalLoteValues, setTotalLoteValues] = useState({});


  // Substitua currentLote por currentLoteIndex
  const currentLote = DataLotes[currentLoteIndex];

  // Função para alterar o lote atual
  function changeLoteIndex(index) {
    const currentLoteItems = DataItem.filter(
      (item) => item.CodigoLote === DataLotes[currentLoteIndex]?.Codigo
    );
    const currentLoteItemCount = currentLoteItems.length;

    if (DataLotes[currentLoteIndex]?.Finalizado || currentLoteItemCount === 0) {
      setCurrentLoteIndex(index);
      clearForm();
    } else {
      ModalErroLoteNaoFinalizado();
    }
  }


  function ModalErroLoteNaoFinalizado() {
    MySwal.fire({
      title: "Erro",
      text: "Você não pode mudar para outro lote enquanto o lote atual não estiver finalizado.",
      icon: "error",
      confirmButtonText: "Ok",
    });
  }

  // Função para editar um lote
  function HandleOpenModalEditLote() {
    SetDescricaoLote(DataLotes[currentLoteIndex].DescricaoLote);
    setShowEditarLote(true);
  }

  function handleAdvance() {
    if (termoReferencia.SepararItensPorLote) {
      const currentLoteItems = DataItem.filter(
        (item) => item.CodigoLote === DataLotes[currentLoteIndex]?.Codigo
      );
      const loteVazio = currentLoteItems.length === 0;

      if (DataLotes[currentLoteIndex]?.Finalizado || loteVazio) {
        navigate(`/prazo-contrato-e-possivel-prorrogacao?code=${code}`);
      } else {
        ModalErroNextPage();
      }
    } else {
      navigate(`/prazo-contrato-e-possivel-prorrogacao?code=${code}`);
    }
  }



  const schema = yup.object().shape({
    valorUnitario: yup
      .number()
      .typeError("O valor unitário deve ser um número")
      .required("O valor unitário é obrigatório")
      .positive("O valor unitário deve ser maior que 0"),
    naturezaItem: yup
      .object()
      .typeError("A natureza do item é obrigatória")
      .required("A natureza do item é obrigatória"),

    unidadeMedida: yup
      .object()
      .required("A natureza do item é obrigatória")
      .typeError("A unidade de medida é obrigatória"),

    quantidade: yup
      .number()
      .typeError("A quantidade é obrigatória")
      .required("A quantidade é obrigatória")
      .positive("A quantidade deve ser maior que 0"),
    DescricaoItem: yup.string().required("A descrição do item é obrigatória"),
    selectCatalogo: yup
      .object()
      .typeError("O tipo é obrigatório")
      .required("O tipo é obrigatório")

  });

  const optionsCatalogo = [
    { value: 1, label: "Material" },
    { value: 2, label: "Serviço" },
  ];

  const optionsNatureza = [
    { value: 1, label: "Produto" },
    { value: 2, label: "Serviço" },
    { value: 3, label: "Medicamento" },
  ];

  // Conta Total de Registros
  useEffect(() => {
    if (termoReferencia.SepararItensPorLote && DataLotes.length > 0) {
      const currentLoteItems = DataItem.filter(item => item.CodigoLote === DataLotes[currentLoteIndex]?.Codigo);
      setItemCount(currentLoteItems.length);
    } else {
      setItemCount(DataItem.length);
    }
  }, [DataLotes, currentLoteIndex, DataItem, termoReferencia.SepararItensPorLote]);

  // Calcular total do lote
  useEffect(() => {
    if (DataLotes[currentLoteIndex]) {
      setTotalLoteValue(CalculaValorTotalLote(DataLotes[currentLoteIndex]));
    }
  }, [DataLotes, DataItem, currentLoteIndex]);


  function ModalErroNextPage() {
    MySwal.fire({
      title: "Erro",
      text: "O Lote atual nao foi finalizado",
      icon: "error",
      confirmButtonText: "Ok",
    });
  }


  function RemoveLote(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeLoteApi(DataLotes[key].Codigo);
        const items = [...DataLotes];
        items.splice(key, 1);
        setDataLotes(items);
        setCurrentLote(0);
        setCurrentIndexList(0);
        MySwal.fire("Excluído!", "Seu Lote foi deletado.", "success");
      }
    });
  }

  async function removeLoteApi(code) {
    try {
      await api.delete(`/api/v1.0/Lote/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) { }
  }

  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeItemApi(key);
        const items = DataItem.filter((item) => item.Codigo !== key);

        setDataItem(items);

        MySwal.fire("Excluído!", "O Item foi deletado.", "success");
      }
    });
  }

  async function removeItemApi(code) {
    try {
      await api.delete(`/api/v1.0/material-servico/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) { }
  }

  function getLotes(codigoTermo) {
    try {
      axios
        .get(`${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${codigoTermo}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            setNewInput(false);
            setDataLotes(res.data);
            //console.log(res.data)
          }
        });
    } catch (error) { }
  }

  function getDetalhesItem(codigoTermo) {
    try {
      axios
        .get(
          `${URL_API}/api/v1.0/material-servico?codigoTermoReferencia=${codigoTermo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setDataItem(res.data);
          }
          setLoad(true);
        });
    } catch (error) { }
  }

  async function getData(code) {
    try {
      searchTermo(state.token, code).then((res) => {
        if (res) {
          setTermoReferencia(res);
          getLotes(res.Codigo);
          getDetalhesItem(res.Codigo);
        }
      });
    } catch (error) {
      ModalError(error);
    }
  }

  async function getUnidadesMedida() {
    try {
      axios
        .get(`${(process.env.REACT_APP_CMS).replace(/\/$/, '')}/api/v1.0/materiais/unidades-de-medida`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let transformMaterial = res.data.map((item) => {
              return {
                value: item.Sigla,
                label: item.Nome,
              };
            })
            setUnidadeMedidaMaterialOptions(transformMaterial);
          }
        });
      axios
        .get(`${(process.env.REACT_APP_CMS).replace(/\/$/, '')}/api/v1.0/servicos/unidades-de-medida`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let transformService = res.data.map((item) => {
              return {
                value: item.Sigla,
                label: item.Nome,
              };
            })
            setUnidadeDeMedidaServiceOptions(transformService);


          }
        });
    } catch (error) { }
  }


  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getUnidadesMedida()
      getData(code);
    } else {
      setLoad(true);
    }
  }

  useEffect(() => {
    LoadData();
  }, []);

  // useEffect(() => {
  //   if (DataLotes[CurrentLote]) {
  //     setCurrentLoteList(CurrentLote);
  //   }
  // }, [CurrentLote]);

  async function handleAdd(e) {
    if (updateValue) {
      const indexUpdate = DataItem.findIndex(
        (objeto) => objeto.Codigo === currentItemUpdate
      );
      UpdateData(e);
      let items = [...DataItem];
      let item = {
        Codigo: currentItemUpdate,
        CodigoTermoReferencia: code,
        CodigoLote: DataLotes[currentLoteIndex]?.Codigo,
        MaterialOuServico: selectCatalogo,
        FKMaterialServico: e.UseCatalogo ? CodigoExterno : e?.codigoExterno,
        Quantidade: e.quantidade,
        NaturezaItem: NaturezaItem,
        DescricaoObjeto: dataEditor,
        UnidadeMedida: UnidadeMedidaText,
        ValorUnitario: e.valorUnitario,
      };
      items[indexUpdate] = item;

      setDataItem(items);

      setUpdateValue(false);
    } else {
      await submitData(e);
    }
  }

  async function clearForm() {
    setCodigoExterno("");
    SetDescricaoLote("");
    setDataEditor("");
    setUnidadeMedidaSelect({});
    setUnidadeMedidaText("");
    setValorUnitario(0);
    setQuantidade(1);
    setNaturezaItem(null);
  }


  async function UpdateData(e) {
    try {
      let data = {
        CodigoTermoReferencia: code,
        CodigoLote: DataLotes[CurrentLote]?.Codigo,
        MaterialOuServico: selectCatalogo?.value,
        FKMaterialServico: e.UseCatalogo ? CodigoExterno : e?.codigoExterno,
        Quantidade: e.quantidade,
        NaturezaItem: NaturezaItem.value,
        DescricaoObjeto: dataEditor,
        UnidadeMedida: UnidadeMedidaText,
        ValorUnitario: e.valorUnitario,
        Codigo: CodigoItem,
      };

      await axios
        .put(`${URL_API}/api/v1.0/material-servico`, data, {
          headers: {
            unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((result) => {
          clearForm();
        });

      let timerInterval;
      MySwal.fire({
        title: "Registro salvo com sucesso!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  async function submitData(e) {
    try {
      // await SubmitTermo(e);

      if (termoReferencia.SepararItensPorLote) {
        if (newInput) {
          await axios
            .post(
              `${URL_API}/api/v1.0/Lote`,
              {
                CodigoTermoReferencia: code,
                DescricaoLote: DescricaoLote,
                ValorTotal: CalculaValorTotalLote(e.Lote),
              },
              {
                headers: {
                  unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
                  Authorization: `Bearer ${state.token}`,
                },
              }
            )

            .then((response) => {
              if (response.data) {
                e.Lote = response.data;

                setDataLotes((lotes) => [...lotes, response.data]);
                submitMaterialServico(e);
                setNewInput(false);
              }
            });

          let timerInterval;
          MySwal.fire({
            title: "Salvo com sucesso!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
            showCancelButton: false,
            showDenyButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
            }
          });
        } else {
          submitMaterialServico(e);
        }
      } else {
        submitMaterialServico(e);
      }
    } catch (error) {
      ModalError(error);
    }
  }

  async function SubmitTermo(e) {
    try {
      let data = {
        Codigo: termoReferencia.Codigo,
        DescricaoObjeto: termoReferencia.DescricaoObjeto,
        CodigoTermoReferencia: termoReferencia.CodigoTermoReferencia,
        NumeroProcessoInterno: termoReferencia.NumeroProcessoInterno,
        SepararItensPorLote: termoReferencia.SepararItensPorLote, // Certifique-se de que este campo está correto
        FormaDisputaLote: termoReferencia.FormaDisputaLote,
        CodigoUnidadeRequisitante: termoReferencia.CodigoUnidadeRequisitante,
        // "Status": contentCategory.Status
      };
      await axios
        .put(`${URL_API}/api/v1.0/termo-referencia`, data, {
          headers: {
            unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(() => {
          navigate(`/detalhamento-itens?code=${code}`);
        });
    } catch (error) {
      ModalError(error);
    }
  }



  function updateTotalValueList() {
    let totalValue = 0;
    DataItem.filter((item) => {
      return (
        item.CodigoLote ===
        DataLotes[currentLoteIndex]?.Codigo
      );
    }).forEach(item => {
      const itemTotal = item.ValorUnitario * item.Quantidade;
      totalValue += itemTotal;
    });


    return totalValue
  }


  async function submitMaterialServico(e) {
    try {
      let data = {
        CodigoTermoReferencia: code,
        CodigoLote: DataLotes[currentLoteIndex]?.Codigo
          ? DataLotes[currentLoteIndex].Codigo
          : e?.Lote?.Codigo,
        MaterialOuServico: selectCatalogo?.value,
        FKMaterialServico: e.UseCatalogo ? CodigoExterno : e?.codigoExterno,
        Quantidade: e.quantidade,
        NaturezaItem: NaturezaItem.value,
        DescricaoObjeto: dataEditor,
        UnidadeMedida: UnidadeMedidaText,
        ValorUnitario: e.valorUnitario,
      };
      await axios
        .post(`${URL_API}/api/v1.0/material-servico`, data, {
          headers: {
            unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((result) => {
          setDataItem([...DataItem, result.data]);
          clearForm();
        });

      let timerInterval;
      MySwal.fire({
        title: "Salvo com sucesso!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    } catch (error) {
      ModalError(error);
    }
  }

  function handleShowCatalogo() {
    if (selectCatalogo?.value === 1) {
      setShowMaterial(true);
    } else {
      setShowServico(true);
    }
  }

  async function handleEditItem(key) {
    setUpdateValue(true);

    const item = DataItem.filter((item) => item.Codigo === key)[0];
    setCurrentItemUpdate(key);

    const filterCatalogo = optionsCatalogo.filter((i) => {
      if (typeof item.MaterialOuServico === "string") {
        return i.label === item.MaterialOuServico;
      } else {
        return i.label === item.MaterialOuServico.label;
      }
    });

    const filterNatureza = optionsNatureza.filter((i) => {
      if (typeof item.NaturezaItem === "string") {
        return i.label === item.NaturezaItem;
      } else {
        return i.label === item.NaturezaItem.label;
      }
    });


    setValorUnitario(item.ValorUnitario);
    setQuantidade(item.Quantidade);
    setCodigoExterno(item?.FKMaterialServico);
    setSelectCatalogo(filterCatalogo[0]);
    setNaturezaItem(filterNatureza[0]);
    setDataEditor(`${item?.DescricaoObjeto}`);
    setCodigoItem(item?.Codigo);
    setUnidadeMedidaText(item?.UnidadeMedida);
  }

  async function HandleEditLote(Finalizar = false, next = false) {
    try {
      const currentLote = DataLotes[currentLoteIndex];
      let valorTotal = 0;

      if (currentLote) {
        // Calcular o valor total do lote antes de enviar o payload
        valorTotal = updateTotalValueList();

        // Atualize o estado do valor total do lote
        setTotalLoteValue(valorTotal);

        const payload = {
          CodigoTermoReferencia: code,
          Codigo: currentLote.Codigo,
          DescricaoLote: DescricaoLote || currentLote.DescricaoLote,
          Finalizado: Finalizar,
          ValorTotal: valorTotal,
          SepararItensPorLote: termoReferencia.SepararItensPorLote, // Certifique-se de que este campo está correto
        };

        await api
          .put(
            "/api/v1.0/Lote",
            payload,
            {
              headers: {
                unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then((res) => {
            if (next && DataLotes[currentLoteIndex + 1]?.Finalizado) {
              setCurrentLoteIndex(DataLotes.length);
              setNewInput(true);
            } else if (next) {
              setCurrentLoteIndex(currentLoteIndex + 1);
              setNewInput(true);
            }
          });
      } else {
        const newLote = {
          CodigoTermoReferencia: code,
          DescricaoLote: DescricaoLote,
          Finalizado: false,
          ValorTotal: valorTotal,
          SepararItensPorLote: termoReferencia.SepararItensPorLote, // Certifique-se de que este campo está correto
        };
        await api
          .post("/api/v1.0/Lote", newLote, {
            headers: {
              unidade_compradora: termoReferencia.CodigoUnidadeRequisitante,
              Authorization: `Bearer ${state.token}`,
            },
          })
          .then((res) => {
            setDataLotes([...DataLotes, res.data]);
            setCurrentLoteIndex(DataLotes.length);
            SetDescricaoLote("");
            setNewInput(false);
          });
      }

      let tempCurrentLote = [...DataLotes];
      tempCurrentLote[currentLoteIndex] = {
        ...tempCurrentLote[currentLoteIndex],
        CodigoTermoReferencia: code,
        DescricaoLote: DescricaoLote || tempCurrentLote[currentLoteIndex].DescricaoLote,
        Finalizado: Finalizar,
        ValorTotal: valorTotal,
      };
      setDataLotes(tempCurrentLote);
    } catch (error) {
      console.error('Error:', error);
      ModalError(error);
    }
  }

  function updateTotalValueList() {
    let totalValue = 0;
    DataItem.filter((item) => {
      return (
        item.CodigoLote ===
        DataLotes[currentLoteIndex]?.Codigo
      );
    }).forEach(item => {
      const itemTotal = item.ValorUnitario * item.Quantidade;
      totalValue += itemTotal;
    });

    return totalValue;
  }

  function CalculaValorTotalLoteVisualizar(lote) {
    let total = 0;
    DataItem.forEach(item => {
      if (item.CodigoLote === lote.Codigo) {
        total += item.ValorUnitario * item.Quantidade;
      }
    });
    return total;
  }

  useEffect(() => {
    const updatedLoteValues = DataLotes.reduce((acc, lote) => {
      acc[lote.Codigo] = CalculaValorTotalLote(lote);
      return acc;
    }, {});
    setTotalLoteValues(updatedLoteValues);
  }, [DataItem, DataLotes]);


  useEffect(() => {
    const initializeData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("code");  // Obter o código dos parâmetros da URL

      if (code) {
        setCode(code);  // Definir o código no estado
        await getUnidadesMedida();
        await getData(code);
      } else {
        setLoad(true);
      }
    };
    initializeData();
  }, [location.search]);


  function handleNewLote() {
    clearForm();
    SetDescricaoLote("");
    setCurrentLoteIndex(DataLotes.length);
    setNewInput(true);
  }


  useEffect(() => {
    if (Object.keys(selectMaterial).length > 0) {
      setCodigoExterno(selectMaterial.CodigoMaterial);
      setDataEditor(selectMaterial.Descricao);
      setValorUnitario(selectMaterial?.Preco?.ValorUnitario);
      setSelectCatalogo(optionsCatalogo[0]);
      setUnidadeMedidaText(selectMaterial.UnidadeMedidaText)
    }
  }, [selectMaterial]);

  useEffect(() => {
    if (Object.keys(selectServico).length > 0) {
      setCodigoExterno(selectServico.CodigoServico);
      setDataEditor(selectServico.Nome);
      setSelectCatalogo(optionsCatalogo[1]);
      setUnidadeMedidaText(selectServico.UnidadeMedidaText)
      // setValorUnitario(selectServico?.Preco?.ValorUnitario)
    }
  }, [selectServico]);

  function HandleOpenModalEditLote() {
    SetDescricaoLote(DataLotes[currentLoteIndex].DescricaoLote);
    setShowEditarLote(true);
  }

  async function getConfiguracao() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_CONFIGURACOES.replace(
          /\/$/,
          ""
        )}/api/v1.0/ConfiguracoesAberta`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      let config = {};
      let pergs = [];
      if (response?.data) {
        const mapaChaves = new Map();
        const dadosFiltrados = [];

        response?.data.forEach((item) => {
          if (!mapaChaves.has(item.Chave)) {
            mapaChaves.set(item.Chave, true);
            dadosFiltrados.push(item);
          }
        });
        await dadosFiltrados.map(async (item) => {
          if (item.Chave == "configuracao_usa_catalogo_tr") {
            setUsaCatalogo(item.Valor == "sim" ? true : false);
          }
        });
      }
    } catch (error) {
      ModalError(error);
    }
  }

  useEffect(() => {
    getConfiguracao();
  }, []);

  return (
    <>
      <Navbar
        button={{ text: "Voltar" }}
        onClick={() => navigate("/")}
        titulo="Cadastrar Termo de Referência"
      />

      <main className="main__estimativa_quantidades">
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={4} code={code} />
            {load ? (
              <div className="col-sm-9 mt-4">
                <h2>Detalhamento dos Itens </h2>

                {termoReferencia?.SepararItensPorLote === true ? (
                  <>
                    <div className="header-lote">
                      <div>
                        <p data-testid="codigo-lote-header">
                          <span>Código do lote</span>
                          {currentLoteIndex + 1}
                        </p>
                        {DataLotes.length > 0 &&
                          DataLotes[currentLoteIndex]?.DescricaoLote && (
                            <p>
                              <span>Descrição Do Lote</span>
                              {removerTags(
                                DataLotes[currentLoteIndex]?.DescricaoLote
                              )}
                            </p>
                          )}
                      </div>
                      {DataLotes.length > 0 && DataLotes[currentLoteIndex] && (
                        <div>
                          <p>
                            <span>Valor Total do Lote</span>
                            {FormataMoeda(updateTotalValueList())}

                          </p>
                          <p>
                            <span>Lote finalizado</span>
                            {DataLotes[currentLoteIndex].Finalizado ? "Sim" : "Não"}
                          </p>
                        </div>
                      )}

                    </div>
                    {DataLotes.length > 0 && DataLotes[currentLoteIndex] && (
                      <div className=" d-flex gap-3 justify-content-end mt-3 mb-3">
                        {DataLotes[currentLoteIndex].Finalizado && (
                          <button
                            className=" btn btn-primary pt-0 pb-0"
                            onClick={handleNewLote}
                          >
                            Novo Lote
                          </button>
                        )}
                        {!DataLotes[currentLoteIndex].Finalizado ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              clearForm();
                              HandleEditLote(true, true);
                            }}
                          >
                            Finalizar Lote
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => HandleEditLote(false, false)}
                          >
                            Novo Item no Lote
                          </button>
                        )}
                        <button
                          className="btn btn-primary w-25"
                          onClick={() => HandleOpenModalEditLote()}
                        >
                          Editar Descrição Do Lote
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <div className="row">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      DescricaoItem: "",
                      UseCatalogo: usaCatalogo,
                      descricao: dataEditor,
                      selectCatalogo: selectCatalogo,
                      codigoExterno: CodigoExterno,
                      unidadeMedida: {},
                      quantidade: Quantidade,
                      valorUnitario: ValorUnitario,
                      naturezaItem: NaturezaItem,
                    }}
                    validationSchema={schema}
                    validateOnBlur={false}
                    onSubmit={(values, { resetForm }) => {
                      handleAdd(values);
                      resetForm();
                    }}
                  >
                    {({ setFieldValue, handleSubmit, values }) => (
                      <>
                        {!DataLotes[currentLoteIndex]?.Finalizado && (
                          <Form
                            className=" d-flex flex-column"
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit();
                            }}
                          >
                            <div className="">
                              {!DataLotes[currentLoteIndex] &&
                                termoReferencia.SepararItensPorLote ? (
                                <>
                                  <span className="mb-2">
                                    Descrição do lote
                                  </span>
                                  <Form.Group className=" editor mt-2 mb-1">
                                    <ReactQuill
                                      id="descricao-lote"
                                      className="h-75"
                                      name="text"
                                      value={DescricaoLote}
                                      onChange={(value) => {
                                        if (removerTags(value)) {
                                          SetDescricaoLote(value);
                                        } else {
                                          SetDescricaoLote("");
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </>
                              ) : (
                                <></>
                              )}
                              <Form.Group className=" d-flex flex-row   gap-2">
                                <Field
                                  className="checkboxForm"
                                  type="checkbox"
                                  name="UseCatalogo"
                                  disabled={true}
                                  // UseCatalogo={values.UseCatalogo}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    // setFieldValue("UseCatalogo", checked);
                                    setUsaCatalogo(checked);
                                  }}
                                />
                                <label className="">
                                  <span className="d-flex flex-row align-items-center">
                                    Usar Catálogo?
                                  </span>
                                </label>
                              </Form.Group>
                              <div className="d-flex  flex-row gap-5 ">
                                {values.UseCatalogo && (
                                  <Button
                                    className=" mt-2 w-25 btn-outline-primary btn "
                                    onClick={() => {
                                      handleShowCatalogo();
                                    }}
                                  >
                                    Pesquisar
                                  </Button>
                                )}
                              </div>
                              <div className="d-flex  flex-row gap-5 ">
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex flex-row align-items-center">
                                      Tipo
                                    </span>
                                  </Form.Label>
                                  <Select
                                    name="selectCatalogo"
                                    placeholder="Selecione"
                                    value={selectCatalogo}
                                    options={optionsCatalogo}
                                    onChange={(selectedOptions) => {
                                      setSelectCatalogo(selectedOptions);
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="selectCatalogo"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex flex-row align-items-center">
                                      Código Externo
                                    </span>
                                  </Form.Label>
                                  <Field
                                    data-testid="codigo-externo"
                                    type="text"
                                    className="form-control"
                                    name="codigoExterno"
                                    value={CodigoExterno}
                                    readOnly={usaCatalogo}
                                    onChange={(e) => {
                                      setCodigoExterno(e.target.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="codigoExterno"
                                  />
                                </Form.Group>
                              </div>
                              <div className="d-flex flex-column">
                                <span>Descrição do Item:</span>

                                <Form.Group className=" mt-2 editor  mb-4" style={{ 'overflow': 'hidden', height: 180 }}>
                                  <ReactQuill
                                    id="descricao-item"
                                    className="h-75"
                                    name="DescricaoItem"
                                    value={dataEditor}
                                    readOnly={usaCatalogo}
                                    onChange={(value) => {
                                      if (removerTags(value)) {
                                        setDataEditor(value);
                                      } else {
                                        setDataEditor("");
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    className="mt-5 erroMessage "
                                    name="DescricaoItem"
                                  />
                                </Form.Group>
                              </div>
                              <div className="d-flex  flex-row gap-5 ">
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label className="">
                                    <span className="d-flex  flex-row align-items-center">
                                      Natureza do Item
                                    </span>
                                  </Form.Label>

                                  <Select
                                    inputId="natureza-item"
                                    name="naturezaItem"
                                    placeholder="Selecione"
                                    classNamePrefix="Selecione"
                                    value={NaturezaItem}
                                    defaultValue={NaturezaItem}
                                    options={optionsNatureza}
                                    onChange={(selectedOptions) => {
                                      setFieldValue(
                                        "naturezaItem",
                                        selectedOptions
                                      );

                                      setNaturezaItem(selectedOptions);
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="naturezaItem"
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex  flex-row align-items-center">
                                      Unidade de Medida
                                    </span>
                                  </Form.Label>

                                  <Field
                                    data-testid="unidade-medida"
                                    type="text"
                                    className="form-control"
                                    name="unidadeMedidaText"
                                    value={UnidadeMedidaText}
                                    defaultValue={UnidadeMedidaText}
                                    readOnly={usaCatalogo}
                                    onChange={(e) => {
                                      // setFieldValue(
                                      //   "unidadeMedidaText",
                                      //   e.target.value
                                      // );
                                      setUnidadeMedidaText(e.target.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="unidadeMedida"
                                  />
                                </Form.Group>
                              </div>
                              <div className="d-flex flex-row w-100 gap-5">
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex flex-row align-items-center">
                                      Quantidade
                                    </span>
                                  </Form.Label>
                                  <Field
                                    data-testid="quantidade"
                                    type="number"
                                    className="form-control"
                                    name="quantidade"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "quantidade",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="quantidade"
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex flex-row align-items-center">
                                      Valor Unitário
                                    </span>
                                  </Form.Label>
                                  <Field
                                    data-testid="valor-unitario"
                                    readOnly={usaCatalogo ? (selectCatalogo?.value == 1 ? true : false) : false}
                                    type="number"
                                    className="form-control"
                                    name="valorUnitario"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "valorUnitario",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="valorUnitario"
                                  />
                                </Form.Group>
                              </div>
                              <div className="d-flex flex-row w-100 gap-5">
                                <Form.Group className="mb-3 p-1 w-50 formGroup">
                                  <Form.Label>
                                    <span className="d-flex flex-row align-items-center">
                                      Valor Total
                                    </span>
                                  </Form.Label>
                                  <Field
                                    data-testid="valor-total"
                                    disabled
                                    type="text"
                                    className="form-control"
                                    value={
                                      values.quantidade * values.valorUnitario
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="mb-3">
                              <Button
                                data-testid="btn-adicionar-item"
                                className="btn btn-primary button"
                                type="submit"
                                onClick={
                                  (handleSubmit,
                                    () => {
                                      setFieldValue("naturezaItem", NaturezaItem);
                                      setFieldValue("DescricaoItem", dataEditor);
                                      setFieldValue("selectCatalogo", selectCatalogo);
                                    })
                                }
                              >
                                {updateValue ? "Editar" : "Adicionar"}
                              </Button>
                              {
                                true &&
                                <Button
                                  className="btn btn-warning button mx-2"
                                  onClick={() => {
                                    setFieldValue("naturezaItem", "");
                                    clearForm();
                                  }}
                                >
                                  {"Limpar"}
                                </Button>
                              }
                            </div>
                          </Form>
                        )}

                        {DataLotes[currentLoteIndex] && (
                          <div className="container-Lote mt-3">
                            <table className="table">
                              <tbody>
                                <tr className={styles.linha__tabbel_listagem}>
                                  <th>Lote</th>
                                  <th>Descrição</th>
                                  <th>Finalizado</th>
                                  <th>Valor Total</th>
                                  <th>Ações</th>
                                </tr>
                                <tr>
                                  <td>{currentLoteIndex + 1}</td>
                                  <td>
                                    {removerTags(
                                      DataLotes[currentLoteIndex]?.DescricaoLote
                                    )}
                                  </td>

                                  <td>
                                    {DataLotes[currentLoteIndex]?.Finalizado
                                      ? "Sim"
                                      : "Não"}
                                  </td>
                                  <td>
                                    {FormataMoeda(updateTotalValueList())}
                                  </td>
                                  {DataLotes[currentLoteIndex]?.Finalizado && (
                                    <td>
                                      <div className=" d-flex flex-row align-items-center justify-content-center ">
                                        <HiPencil
                                          type="button"
                                          name="teste"
                                          color="black"
                                          onClick={() => {
                                            setCurrentLote(currentLoteIndex);
                                            HandleOpenModalEditLote();
                                          }}

                                        />

                                        <MdCancel
                                          type="button"
                                          style={{ color: "red" }}
                                          onClick={() =>
                                            RemoveLote(currentLoteIndex)
                                          }
                                        />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                        <div className="container-Lote">
                          <table className="table">
                            <tbody>
                              <tr className={styles.linha__tabbel_listagem}>
                                <th>Item</th>
                                <th>Descrição</th>
                                <th>Un. Medida</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Total</th>
                                <th>Ações</th>
                              </tr>

                              {
                                // Quando o termo de referencia não separa por lote
                                DataItem?.length > 0 &&
                                !termoReferencia.SepararItensPorLote &&
                                DataItem.map((i, index) => {
                                  return (
                                    <tr key={index * Math.random()}>
                                      <td>{index + 1}</td>
                                      <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                        {removerTags(i.DescricaoObjeto)}
                                      </td>
                                      <td> {i.UnidadeMedida}</td>
                                      <td>{i.Quantidade}</td>
                                      <td>{FormataMoeda(i.ValorUnitario)}</td>
                                      <td>
                                        {FormataMoeda(i.ValorUnitario * i.Quantidade)}
                                      </td>
                                      <td>
                                        <div className=" d-flex flex-row align-items-center justify-content-center ">
                                          <HiPencil
                                            type="button"
                                            name="teste"
                                            color="black"
                                            onClick={() =>
                                              handleEditItem(
                                                i.Codigo,
                                                setFieldValue
                                              )
                                            }
                                          />

                                          <MdCancel
                                            type="button"
                                            style={{ color: "red" }}
                                            onClick={() =>
                                              RemoveItem(i.Codigo)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }

                              {
                                // Quando o termo de referencia separa por lote
                                DataItem?.length > 0 &&
                                termoReferencia.SepararItensPorLote &&
                                DataItem.filter((item) => {
                                  return (
                                    item.CodigoLote ===
                                    DataLotes[currentLoteIndex]?.Codigo
                                  );
                                })?.map((i, index) => {
                                  return (
                                    <tr key={index * Math.random()}>
                                      <td>{index + 1}</td>
                                      <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                        {removerTags(i.DescricaoObjeto)}
                                      </td>
                                      <td> {i.UnidadeMedida}</td>
                                      <td>{i.Quantidade}</td>
                                      <td>{FormataMoeda(i.ValorUnitario)}</td>
                                      <td>
                                        {FormataMoeda(i.ValorUnitario * i.Quantidade)}
                                      </td>
                                      <td>
                                        <div className=" d-flex flex-row align-items-center justify-content-center ">
                                          <HiPencil
                                            type="button"
                                            name="teste"
                                            color="black"
                                            onClick={() => {
                                              HandleEditLote(false, false);
                                              handleEditItem(
                                                i.Codigo,
                                                setFieldValue
                                              )
                                            }


                                            }
                                          />

                                          <MdCancel
                                            type="button"
                                            style={{ color: "red" }}
                                            onClick={() =>
                                              RemoveItem(i.Codigo)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                          <div className="containerPageTable">
                            {termoReferencia.SepararItensPorLote &&
                              DataLotes.length >= 1 && (
                                <>
                                  <div className="PageTable">
                                    <div>{`<<<`}</div>
                                    <div>{`<`}</div>
                                    {DataLotes.map((i, index) => {
                                      return (
                                        <button
                                          className="btn  p-0"
                                          onClick={() => changeLoteIndex(index)}
                                          key={index * Math.random()}
                                        >
                                          {index + 1}
                                        </button>
                                      );
                                    })}
                                  </div>
                                  <div className="TotalValueTable">
                                    <div className="">Total de registros</div>
                                    {
                                      itemCount
                                      // DataItem.filter((item) => {
                                      //   return (
                                      //     item.CodigoLote ===
                                      //     DataLotes[CurrentLote]?.Codigo
                                      //   );
                                      // }).length
                                    }
                                    <div className="0"> </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </Formik>

                  <div>

                    <Button
                      className="m-2"
                      onClick={() => {
                        handleAdvance();
                      }}
                    >
                      Salvar e Continuar
                    </Button>
                    <Button
                      className="m-2"
                      onClick={() => {
                        setShowEtapa(true)

                      }}
                    >
                      Visualizar
                    </Button>
                  </div>

                  {/* Modal Preencher etapa */}
                  <ModalEditarLote
                    show={showEditarLote}
                    setShow={setShowEditarLote}
                    dataEditor={DescricaoLote}
                    setDataEditor={SetDescricaoLote}
                    Finalizado={DataLotes[currentLoteIndex]?.Finalizado}
                    submitData={(i) => HandleEditLote(i)}
                    currentLote={DataLotes[currentLoteIndex]}
                  />
                  <ModalMaterial
                    show={showMaterial}
                    setShow={setShowMaterial}
                    setSelectItem={setSelectMaterial}
                  />
                  <ModalServico
                    show={showServico}
                    setShow={setShowServico}
                    setSelectItem={setSelectServico}
                  />

                  <Modal
                    show={showEtapa}
                    onHide={handleCloseEtapa}
                    size="xl"
                    animation={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Detalhamento dos Itens
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {DataItem?.length > 0 && !termoReferencia.SepararItensPorLote && (
                        <div className="container-Lote">
                          <table className="table">
                            <tbody>
                              <tr className={styles.linha__tabbel_listagem}>
                                <th>Item</th>
                                <th>Descrição</th>
                                <th>Un. Medida</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Total</th>
                              </tr>
                              {DataItem.map((i, index) => (
                                <tr key={index * Math.random()}>
                                  <td>{index + 1}</td>
                                  <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                    {removerTags(i.DescricaoObjeto)}
                                  </td>
                                  <td>{i.UnidadeMedida}</td>
                                  <td>{i.Quantidade}</td>
                                  <td>{FormataMoeda(i.ValorUnitario)}</td>
                                  <td>{FormataMoeda(i.ValorUnitario * i.Quantidade)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}


                      {/* Quando o termo de referencia separa por lote */}
                      {DataLotes && DataLotes.map((lote, i) => (
                        <div className="container-Lote mt-3" key={i * Math.random()}>
                          <table className="table">
                            <tbody>
                              <tr className={styles.linha__tabbel_listagem}>
                                <th>Lote</th>
                                <th>Descrição</th>
                                <th>Finalizado</th>
                                <th>Valor Total</th>
                              </tr>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  {removerTags(
                                    lote?.DescricaoLote
                                  )}
                                </td>

                                <td>
                                  {lote?.Finalizado
                                    ? "Sim"
                                    : "Não"}
                                </td>
                                <td>
                                  {FormataMoeda(CalculaValorTotalLoteVisualizar(lote))}
                                </td>

                              </tr>
                            </tbody>
                          </table>
                          <table className="table">
                            <tbody>
                              <tr className={styles.linha__tabbel_listagem}>
                                <th>Item</th>
                                <th>Descrição</th>
                                <th>Un. Medida</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Total</th>
                              </tr>

                              {


                                DataItem.filter((item) => {
                                  return (
                                    item.CodigoLote ===
                                    lote?.Codigo
                                  )
                                }
                                ).map((item, index) => {
                                  return (
                                    <tr key={index * Math.random()}>
                                      <td>{index + 1}</td>
                                      <td style={{ maxWidth: 350, overflow: 'auto' }}>
                                        {removerTags(item.DescricaoObjeto)}
                                      </td>
                                      <td> {item.UnidadeMedida}</td>
                                      <td>{item.Quantidade}</td>
                                      <td>{FormataMoeda(item.ValorUnitario)}</td>
                                      <td>
                                        {FormataMoeda(item.ValorUnitario * item.Quantidade)}
                                      </td>

                                    </tr>
                                  );
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleCloseEtapa}>
                        Voltar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Modal Visualização */}
                </div>
              </div>
            ) : (
              <DetalhesItemSkeleton />
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
}
