export function CalculaValorTotalLote(lote) {
	console.log('CalculaValorTotalLote', lote);
	let totalValue = 0;

	// Verifica se o lote possui a propriedade MateriaisServicos e itera sobre ela
	(lote?.MateriaisServicos || []).forEach(item => {
		// Calcula o valor total de cada item multiplicando o ValorUnitario pela Quantidade
		const itemTotal = item.ValorUnitario * item.Quantidade;
		// Adiciona o valor total do item ao valor total do lote
		totalValue += itemTotal;
	});

	// Retorna o valor total do lote
	return totalValue;
}