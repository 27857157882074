import axios from 'axios';

const getAnexos = async (codigoTermoReferencia, token) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASEURL}api/v1.0/anexos?codigoTermoReferencia=${codigoTermoReferencia}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error('Error fetching anexos:', error);
    }
};

export default {
    getAnexos,
};
