import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalError } from "../ModalError/ModalError";
import { searchTermo } from "../../functions/ETP/searchTermo";
import { useLocation } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import axios from "axios";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";
import { GetContentEtapa } from "../../functions/Etapa/GetContentEtapa";
import { ModalErrorMessage } from "../ModalErrorMessage/ModalErrorMessage";
import { EtapasApiCode } from "../../Dictionary/EtapasApiCode";
import { ListEtapas } from "../../Dictionary/ListEtapas";
import api from "../../services/api";
import { FormataMoeda } from "../../functions/FormataMoeda";
import { PreviewEtapa } from "../PreviewEtapa/PreviewEtapa";

export function ModalPreViewEtapa({ showPreview, setShow, codigoTermo = null }) {
  const handleClose = () => setShow(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [DataTermo, setDataTermo] = useState({});
  const [code, setCode] = useState("");
  const { state } = useContext(AuthContext);
  const [DataLotes, setDataLotes] = useState([]);
  const [DataEtapas, setDataEtapas] = useState([]);
  const URL_API = (process.env.REACT_APP_BASEURL).replace(/\/$/, '')
  const [selectedFiles, setSelectedFiles] = useState([]);

  async function getData(code) {
    try {
      await searchTermo(state.token, code).then((result) => {
        if (result) {
          setDataTermo(result);

        }

      });
    } catch (error) {
      ModalError(error);
    }
  }

  function getLotes(code) {
    try {
      axios
        .get(
          `${URL_API}/api/v1.0/Lote?codigoTermoReferencia=${code}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setDataLotes(res.data);
          }
        });
    } catch (error) { }
  }

  async function getDataAnexo(code) {
    try {
      api
        .get(`/api/v1.0/anexos?codigoTermoReferencia=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let dataRes = res.data
              .filter((item) => item.Status !== "Deletado")
              .map((item) => ({
                name: item?.Nome + "." + item.Extensao,
                size: item?.Tamanho,
                codigoFile: item.Codigo,
                CodigoTermoReferencia: item.CodigoTermoReferencia,
                CodigoExterno: item.CodigoExterno,
                Status: item.Status,
              }));

            setSelectedFiles(dataRes);
          }
        });
    } catch (error) { }
  }

  async function getEtapas(code, currentEtapa) {
    try {
      GetContentEtapa(code, currentEtapa, state.token).then((contentEtapa) => {
        if (contentEtapa) {
          const listaMatch = contentEtapa
            .map((item) => EtapasApiCode[item.NumeroEtapa])
            .filter((numero) => numero !== undefined)
            .map((numero) => {
              return {
                numero: numero,
                content: contentEtapa.find(
                  (item) => EtapasApiCode[item.NumeroEtapa] === numero
                ).Descricao,
                justificada: contentEtapa.find(

                  (item) => {
                    return EtapasApiCode[item.NumeroEtapa] === numero
                  }
                ).EtapaPreenchida,
              };
            });

          const temp = listaMatch.map((valor) => {
            const etapaCorrespondente = ListEtapas.find(
              (etapa) => etapa.posit === valor.numero + 4
            );
            return {
              ...valor,
              nome: etapaCorrespondente ? etapaCorrespondente.name : undefined,
            };
          });

          setDataEtapas(temp);
        }
      });
    } catch (error) {
      ModalErrorMessage(error);
    }

  }
  useEffect(() => {

    const code = searchParams.get("code");
    if (code !== null || codigoTermo) {
      setCode(codigoTermo ?? code);
      getDataAnexo(codigoTermo ?? code);
      getEtapas(codigoTermo ?? code, "all");
      getLotes(codigoTermo ?? code)
      getData(codigoTermo ?? code);
    }

  }, []);
  return (
    <Modal size="xl" show={showPreview} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Prévia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PreviewEtapa />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
