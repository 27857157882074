import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";

import { useEffect } from "react";

export function ModalEditarLote({
  show,
  setShow,
  dataEditor,
  setDataEditor,
  submitData,
  Finalizado,
  currentLote, // Adicionando a prop para o lote corrente
}) {
  const handleClose = () => setShow(false);

  // Sincronizar o valor do editor com a descrição do lote corrente
  useEffect(() => {
    if (show && currentLote) {
      setDataEditor(currentLote.DescricaoLote);
    }
  }, [show, currentLote]);

  function handleEditLote() {
    submitData(Finalizado);
    handleClose();
  }
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Alterar descrição do lote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <span className="mb-2">Descrição do lote</span>
          <div className=" editor mt-2 mb-1">
            <ReactQuill
              className="h-75"
              name="text"
              value={dataEditor}
              onChange={(value) => {
                if (removerTags(value)) {
                  setDataEditor(value);
                } else {
                  setDataEditor("");
                }
              }}
            />
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleEditLote()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
